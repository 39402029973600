import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountdown, useEffectOnce } from 'usehooks-ts';

import { MessageBox } from 'modules/common/components';

interface ReauthPopUpProps {
  remainingTimeInMs: number;
  isOpen: boolean;
  onClick: () => void;
}

export const ReauthPopUp: FC<ReauthPopUpProps> = ({ remainingTimeInMs, isOpen, onClick }) => {
  const { t } = useTranslation();

  const [count, { startCountdown }] = useCountdown({
    countStart: Math.floor(remainingTimeInMs / 1000), // useCountdown accepts seconds here, not ms
    intervalMs: 1000,
  });

  useEffectOnce(() => {
    startCountdown();
  });

  return (
    <MessageBox
      isOpen={isOpen}
      text={t('common.stayLoggedInMsg', { time: format(count * 1000, 'mm:ss') })}
      title={t('common.stayLoggedInTitle')}
      onClick={onClick}
      buttonLabel={t('common.yes')}
    />
  );
};
