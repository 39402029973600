import { FC, PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  title?: string | null;
  className?: string;
}

export const Modal: FC<ModalProps> = ({ children, className, isOpen, title }) => {
  const styling = `relative rounded bg-neutral-300 z-20 shadow-md ${className}`;

  ReactModal.setAppElement('#root');

  return (
    <ReactModal
      isOpen={isOpen}
      className="border-none bg-none backdrop-blur-0"
      style={{
        overlay: { background: 'rgba(187, 187, 187, 0.8)', zIndex: '99' },
      }}
    >
      <div className="w-screen h-screen absolute flex justify-center items-center backdrop-blur-sm">
        <div className={styling}>
          {title && (
            <p className="absolute left-2 top-2 text-sm font-bold py-1 px-2 bg-neutral-500 -m-2 rounded-tl rounded-br">
              {title}
            </p>
          )}
          {children}
        </div>
      </div>
    </ReactModal>
  );
};
