import { Operator } from 'modules/common';
import { USER_TYPES } from 'modules/users';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
  refreshTokenExpiresInMs: number;
}

export type RefreshTokenResponse = Tokens;

export enum GROUP_ROLE_ACCESS {
  NO_ACCESS = 'no_access',
  READ = 'read',
  WRITE = 'write',
}

export interface Role {
  _id: string;
  access: GROUP_ROLE_ACCESS;
  groupId: string;
  role: USER_ROLE_KEYS;
}

export interface Group {
  _id: string;
  name: string;
  roles: Role[];
}

export interface WhoAmIResponse {
  userUId: string;
  firstName: string;
  lastName: string;
  email: string;
  group: Group;
  organizations: Operator[];
  type: USER_TYPES;
}

export interface LogoutResponse {
  message: string;
}

export enum LOGIN_FIELDS {
  EMAIL = 'email',
  PASSWORD = 'password',
  STAY_LOGGED_IN = 'stayLoggedIn',
  VERIFICATION_CODE = 'verificationCode',
  TURNSTILE_TOKEN = 'turnstileToken',
}

export interface LoginFields {
  [LOGIN_FIELDS.EMAIL]: string;
  [LOGIN_FIELDS.PASSWORD]: string;
  [LOGIN_FIELDS.STAY_LOGGED_IN]: boolean;
  [LOGIN_FIELDS.VERIFICATION_CODE]?: string;
  [LOGIN_FIELDS.TURNSTILE_TOKEN]?: string;
}

export type LoginBody = LoginFields;

export interface LoginResponse extends RefreshTokenResponse {
  userUId: string;
}

export interface RefreshTokensRequest {
  refreshToken?: string;
  stayLoggedIn: boolean;
}

export type ForgottenPasswordFields = Record<LOGIN_FIELDS.EMAIL, string>;
export type ForgottenPasswordBody = ForgottenPasswordFields;

export type ForgottenPasswordReponse = boolean;

export interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  isLoggedIn: boolean;
  stayLoggedIn: boolean;
  refreshTokenExpiresAtInMs: number | null;
  roles: Role[];
  firstName: string;
  lastName: string;
  userUId: string;
  operators: Operator[];
  selectedOperator: Operator | null;
  selectedDeviceId?: string;
  userType: USER_TYPES | null;
}

export enum USER_ROLE_KEYS {
  USER_MODIFICATION = 'user_modification',
  SUPER = 'super',
  MANAGE_PRICES = 'manage_prices',
  MANAGE_PAYOUTS = 'manage_payouts',
  DROP_OFF_MANAGEMENT = 'drop_off_management',
  DROPPED_BAG_MANAGEMENT = 'dropped_bag_management',
  FREE_BAG_MANAGEMENT = 'free_bag_management',
  BAG_SCANNERS = 'bag_scanners',
  ACTIVITIES = 'activities',
  MANAGE_USER = 'manage_user',
  MANAGE_CREDENTIALS = 'manage_credentials',
  SEND_CUSTOM_NOTIFICATION = 'send_custom_notification',
  BAG_PROCESS_API_ACCESS_MANAGE = 'bag_process_api_access_manage',
  TRANSACTION_MANAGEMENT = 'transaction_management',
  CONTAINER_MANAGEMENT = 'container_management',
  VENDING_MACHINES = 'vending_machines',
}
