/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { CONNECT_TYPE, WaitingQueueData, WaitingQueueState } from 'modules/container-boxes/types';

const initialState: WaitingQueueState = {
  data: {
    type: CONNECT_TYPE.JOIN,
    containerBoxUId: '',
    affectedWaitingTicket: '',
    waitingQueue: [],
    isLoading: false,
  },
};

const handleWaitingRoomData = (
  state: WaitingQueueState,
  { payload }: PayloadAction<WaitingQueueData>,
) => {
  state.data = payload;
};

export const waitingQueueSlice = createSlice({
  name: 'waitingQueue',
  initialState,
  reducers: {
    getWaitingRoomData: (state, action) => {
      handleWaitingRoomData(state, action);
    },
  },
});

export const waitingQueueReducer = waitingQueueSlice.reducer;

export const { getWaitingRoomData } = waitingQueueSlice.actions;

export const getWaitingQueueTickets = (state: RootState) => state.waitingQueue.data.waitingQueue;
