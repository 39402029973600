import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ActiveScannerProps {
  bagsScanned: number;
  certificate: string;
  deviceId: string;
  index: number;
  bagsScannedToday: number;
}

export const ActiveScanner: FC<ActiveScannerProps> = ({
  bagsScanned,
  certificate,
  deviceId,
  index,
  bagsScannedToday,
}) => {
  const { t } = useTranslation();
  return (
    <Link className="w-48" to={`/scanners/detail/${deviceId}`}>
      <div className="w-48 mt-4 min-h-[8rem] shadow-md h-min-2 hover:shadow-xl transition-all flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="bg-neutral-200 py-1 px-3 border border-neutral-400 text-neutral-400 text-sm font-light">
            {index}
          </div>
          <span className="text-neutral-400 text-sm font-light pr-2 pt-1">{certificate}</span>
        </div>
        <div className="flex flex-col text-center">
          <span>{t('dashboard.bagsScanned', { count: bagsScanned ?? 0 })}</span>
          <span>{t('dashboard.bagsScannedToday', { count: bagsScannedToday ?? 0 })}</span>
        </div>
      </div>
    </Link>
  );
};
