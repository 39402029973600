import { useMemo, useState } from 'react';

import { DEFAULT_POLLING_INTERVAL, useAppSelector } from 'modules/common';
import { CONTAINER_BOX_STATUS } from 'modules/container-boxes';
import { getDashboardContainerBoxes } from 'modules/container-boxes/store';
import { getBagsTodayTime, getBagsWaitingTime, getScannersTime } from 'modules/dashboard';
import {
  useGetBagsWaitingQuery,
  useGetDashboardContainerBoxesQuery,
  useGetScannersQuery,
  useGetTodayBagsQuery,
} from 'modules/dashboard/api';
import { ContainerBoxForDashboard, ScannerItem } from 'modules/dashboard/types';
import { SCANNING_DEVICE_STATUS } from 'modules/devices';

export const useDashboard = () => {
  const bagsTodayUpdatedAt = useAppSelector(getBagsTodayTime);
  const scannersUpdatedAt = useAppSelector(getScannersTime);
  const bagsWaitingUpdatedAt = useAppSelector(getBagsWaitingTime);
  const containerBoxes = useAppSelector(getDashboardContainerBoxes);

  const { data, isLoading, refetch } = useGetTodayBagsQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
  });
  const {
    data: scanners,
    isLoading: scannersLoading,
    refetch: scannersRefetch,
  } = useGetScannersQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
  });
  const { isLoading: containerBoxesLoading, refetch: containerBoxesRefetch } =
    useGetDashboardContainerBoxesQuery();
  const {
    data: bagsWaiting,
    isLoading: bagsWaitingLoading,
    refetch: bagsWaitingRefetch,
  } = useGetBagsWaitingQuery(undefined, {
    pollingInterval: DEFAULT_POLLING_INTERVAL,
  });

  const [bagsWaitingPercentage, setBagsWaitingPercentage] = useState<{
    oneDay: string;
    twoDays: string;
    threeDays: string;
    fourAndMoreDays: string;
  }>();

  useMemo(() => {
    if (bagsWaiting) {
      const total =
        bagsWaiting.oneDay +
        bagsWaiting.twoDays +
        bagsWaiting.threeDays +
        bagsWaiting.fourAndMoreDays;

      setBagsWaitingPercentage({
        oneDay: `${(bagsWaiting.oneDay / total) * 100}`,
        twoDays: `${(bagsWaiting.twoDays / total) * 100}`,
        threeDays: `${(bagsWaiting.threeDays / total) * 100}`,
        fourAndMoreDays: `${(bagsWaiting.fourAndMoreDays / total) * 100}`,
      });
    }
  }, [bagsWaiting]);

  const [activeScanners, setActiveScanners] = useState<ScannerItem[]>([]);
  const [suspendedScanners, setSuspendedScanners] = useState<ScannerItem[]>([]);

  const [activeContainerBoxes, setActiveContainerBoxes] = useState<ContainerBoxForDashboard[]>([]);
  const [suspendedContainerBoxes, setSuspendedContainerBoxes] = useState<
    ContainerBoxForDashboard[]
  >([]);

  useMemo(() => {
    if (scanners?.data.length) {
      setActiveScanners(
        scanners.data.filter((scanner) => scanner.status === SCANNING_DEVICE_STATUS.ACTIVE),
      );

      setSuspendedScanners(
        scanners.data.filter((scanner) => scanner.status === SCANNING_DEVICE_STATUS.SUSPENDED),
      );
    }
  }, [scanners]);

  useMemo(() => {
    if (containerBoxes?.length) {
      setActiveContainerBoxes(
        containerBoxes.filter(
          (containerBox) => containerBox.status === CONTAINER_BOX_STATUS.ACTIVE,
        ),
      );

      setSuspendedContainerBoxes(
        containerBoxes.filter(
          (containerBox) => containerBox.status === CONTAINER_BOX_STATUS.SUSPENDED,
        ),
      );
    }
  }, [containerBoxes]);

  return {
    bagsToday: data,
    activeScanners,
    suspendedScanners,
    scannersLoading,
    bagsWaiting,
    bagsWaitingPercentage,
    bagsWaitingLoading,
    bagsWaitingRefetch,
    isLoading,
    refetch,
    scannersRefetch,
    bagsTodayUpdatedAt,
    scannersUpdatedAt,
    bagsWaitingUpdatedAt,
    activeContainerBoxes,
    suspendedContainerBoxes,
    containerBoxesLoading,
    containerBoxesRefetch,
  };
};
