/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import {
  DashboardContainerBoxesState,
  DashboardContainerBoxWaitingRoomChange,
  DashboardContainerDoorBagDepositedChange,
} from 'modules/container-boxes/types';
import { dashboardApi } from 'modules/dashboard';

const initialState: DashboardContainerBoxesState = {
  data: [],
};

const handleWaitingRoomChange = (
  state: DashboardContainerBoxesState,
  data: DashboardContainerBoxWaitingRoomChange,
) => {
  const containerBoxIndex = state.data.findIndex(
    (containerBox) => containerBox.containerBoxUId === data.containerBoxUId,
  );

  if (containerBoxIndex !== -1) {
    state.data[containerBoxIndex].inQueue = data.value;
  }
};

const handleBagDeposited = (
  state: DashboardContainerBoxesState,
  data: DashboardContainerDoorBagDepositedChange,
) => {
  const containerBoxIndex = state.data.findIndex((containerBox) =>
    containerBox.scanningDevices.some((device) => device.deviceId === data.deviceId),
  );

  if (containerBoxIndex !== -1) {
    state.data[containerBoxIndex].scanningDevices = state.data[
      containerBoxIndex
    ].scanningDevices.map((device) => {
      if (device.deviceId === data.deviceId) {
        return {
          ...device,
          bagsDroppedInOngoingSession: data.value,
        };
      }
      return device;
    });
  }
};

export const dashboardContainerBoxesSlice = createSlice({
  name: 'dashboardContainerBoxes',
  initialState,
  reducers: {
    updateQueueOnDashboardContainerBox: (state, action) => {
      handleWaitingRoomChange(state, action.payload);
    },
    updateBagsDepositedOnScanner: (state, action) => {
      handleBagDeposited(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      dashboardApi.endpoints.getDashboardContainerBoxes.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) return;

        state.data = payload.data;
      },
    );
  },
});

export const dashboardContainerBoxesReducer = dashboardContainerBoxesSlice.reducer;

export const { updateQueueOnDashboardContainerBox, updateBagsDepositedOnScanner } =
  dashboardContainerBoxesSlice.actions;

export const getDashboardContainerBoxes = (state: RootState) => state.dashboardContainerBoxes.data;
