import * as yup from 'yup';

import { StringSchemaGenerator, VALIDATION_ERRORS, getErrorMessage } from 'modules/common';
import {
  ADD_VENDING_MACHINE_FIELDS,
  AddNewVendingMachineFields,
  VENDING_MACHINE_STATUS,
  UpdateVendingMachineFields,
} from 'modules/vending-machines/types';

export const AddNewVendingMachineSchema: yup.Schema<AddNewVendingMachineFields> = yup
  .object()
  .shape({
    deviceId: StringSchemaGenerator(ADD_VENDING_MACHINE_FIELDS.DEVICE_ID),
    containerBoxUId: yup.string(),
    status: yup
      .mixed<VENDING_MACHINE_STATUS>()
      .oneOf(Object.values(VENDING_MACHINE_STATUS))
      .nonNullable()
      .required(
        getErrorMessage(ADD_VENDING_MACHINE_FIELDS.STATUS, VALIDATION_ERRORS.REQUIRED_FIELD),
      ),
    notes: yup.string().notRequired().nonNullable(),
    isOpen: yup.boolean().required(),
  });

export const UpdateVendingMachineSchema: yup.Schema<UpdateVendingMachineFields> = yup
  .object()
  .shape({
    status: yup
      .mixed<VENDING_MACHINE_STATUS>()
      .oneOf(Object.values(VENDING_MACHINE_STATUS))
      .nonNullable(),
    isOpen: yup.boolean(),
  });
