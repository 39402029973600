import { FC } from 'react';
import { Link } from 'react-router-dom';

import { PATHS } from 'router/utils';

interface SuspendedContainerBoxProps {
  name: string;
  id: string;
}

export const SuspendedContainerBox: FC<SuspendedContainerBoxProps> = ({ name, id }) => {
  return (
    <Link to={`${PATHS.CONTAINER_BOX_DETAILS}/${id}`}>
      <div className="font-bold shadow-md hover:shadow-xl transition-all bg-neutral-300 text-center py-2 px-4">
        {name}
      </div>
    </Link>
  );
};
