import * as yup from 'yup';

import { StringSchemaGenerator, VALIDATION_ERRORS, getErrorMessage } from 'modules/common';
import {
  NOTIFICATION_PLATFORMS,
  SEND_CUSTOM_NOTIFICATION_FIELDS,
  SEND_TO_TYPE,
  SendNotificationFields,
} from 'modules/notifications/types';

import { MAXIMUM_LENGTH_OF_BODY_TEXT } from '../constants';

export const SendNotificationsSchema: yup.Schema<SendNotificationFields> = yup.object().shape({
  sendToType: yup
    .mixed<SEND_TO_TYPE>()
    .oneOf(Object.values(SEND_TO_TYPE))
    .nonNullable()
    .required(
      getErrorMessage(
        SEND_CUSTOM_NOTIFICATION_FIELDS.SEND_TO_TYPE_FIELD,
        VALIDATION_ERRORS.REQUIRED_FIELD,
      ),
    ),
  sendToUserUId: yup.string().when(SEND_CUSTOM_NOTIFICATION_FIELDS.SEND_TO_TYPE_FIELD, {
    is: SEND_TO_TYPE.SINGLE_CUSTOMER,
    then: () => StringSchemaGenerator(SEND_CUSTOM_NOTIFICATION_FIELDS.SEND_TO_USERUID),
  }),
  titleText: StringSchemaGenerator(SEND_CUSTOM_NOTIFICATION_FIELDS.TITLE_TEXT),
  bodyText: StringSchemaGenerator(SEND_CUSTOM_NOTIFICATION_FIELDS.BODY_TEXT).test(
    SEND_CUSTOM_NOTIFICATION_FIELDS.BODY_TEXT,
    getErrorMessage(
      SEND_CUSTOM_NOTIFICATION_FIELDS.BODY_TEXT,
      VALIDATION_ERRORS.ABOVE_MAX_CHARACTERS,
    ) ?? '',
    (val) => val.length <= MAXIMUM_LENGTH_OF_BODY_TEXT,
  ),
  acceptText: yup.string(),
  platformType: yup
    .mixed<NOTIFICATION_PLATFORMS>()
    .oneOf(Object.values(NOTIFICATION_PLATFORMS))
    .nonNullable()
    .required(
      getErrorMessage(
        SEND_CUSTOM_NOTIFICATION_FIELDS.PLATFORM_TYPE,
        VALIDATION_ERRORS.REQUIRED_FIELD,
      ),
    ),
});
