import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const bagRollPriceModule = () => import('modules/bag-roll-price');

export const bagRollPriceRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.BAG_ROLL_PRICE,
        lazy: lazyImportComponent(bagRollPriceModule, 'BagRollPriceView'),
      },
    ],
  },
];
