import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import UserIcon from 'assets/icons/user.svg';
import { getName, useAuth } from 'modules/auth';
import { Button, SubnavigationMenu } from 'modules/common/components';
import { useAppSelector } from 'modules/common/hooks';

export const ProfileDropDownMenu: FC = () => {
  const { t } = useTranslation();

  const { handleLogout } = useAuth();
  const { firstName, lastName } = useAppSelector(getName);

  return (
    <SubnavigationMenu
      id="profileSubNav"
      img={UserIcon}
      label={`${firstName} ${lastName[0]}.`}
      className="w-[200px]"
      dropDownClassName="w-[200px]"
    >
      <div className="h-20 flex justify-center">
        <Button
          type="button"
          onClick={handleLogout}
          label={t('common.logout')}
          className="text-black bg-neutral-50 hover:bg-neutral-300 my-auto w-1/2"
          id="logout-btn"
        />
      </div>
    </SubnavigationMenu>
  );
};
