import toast from 'react-hot-toast';

import { quickDropAdminApi } from 'config/api';
import i18n from 'config/translation';
import { UserLogInfoList } from 'modules/accounts';
import { REQUEST_METHODS } from 'modules/common';
import { NOTIFICATIONS_API_URLS } from 'modules/notifications/constants';
import {
  CustomNotificationsListQueryParams,
  SendNotificationBody,
} from 'modules/notifications/types';

const SUCCESSFUL_NOTIFICATION_TOAST_DURATION_MS = 5000;

export const notificationsApi = quickDropAdminApi.injectEndpoints({
  endpoints: (builder) => ({
    sendNotification: builder.mutation<boolean, SendNotificationBody>({
      query: (body) => ({
        url: NOTIFICATIONS_API_URLS.SEND_CUSTOM_NOTIFICATION,
        body,
        method: REQUEST_METHODS.POST,
      }),
      extraOptions: { disableDefaultSuccessToaster: true },
      onQueryStarted(_args, { queryFulfilled }) {
        queryFulfilled.then(() => {
          toast.success(i18n.t('common.successfullySent'), {
            duration: SUCCESSFUL_NOTIFICATION_TOAST_DURATION_MS,
          });
        });
      },
    }),
    getCustomNotificationsList: builder.query<UserLogInfoList, CustomNotificationsListQueryParams>({
      query: ({ page, take, filters, sort, order }) => ({
        url: NOTIFICATIONS_API_URLS.GET_CUSTOM_NOTIFICATIONS_LIST,
        params: {
          'pagination[take]': take,
          'pagination[page]': page,
          [`sort[${sort}]`]: order,
          ...filters,
        },
        method: REQUEST_METHODS.GET,
      }),
    }),
  }),
});

export const { useSendNotificationMutation, useGetCustomNotificationsListQuery } = notificationsApi;
