interface EnvStyles {
  bgStyling: string;
  textStyling: string;
}

export const useStylesByEnv = (): EnvStyles => {
  const env = import.meta.env.VITE_APP_ENV;

  const envStyles = {
    bgStyling: '',
    textStyling: '',
  };

  switch (env) {
    case 'dev':
      envStyles.bgStyling = 'bg-[#FF7B31]';
      envStyles.textStyling = 'text-white';
      break;
    case 'staging':
      envStyles.bgStyling = 'bg-[#FE46B4]';
      envStyles.textStyling = 'text-white';
      break;
    default:
      envStyles.bgStyling = 'bg-neutral-700';
      envStyles.textStyling = 'text-neutral-300';
  }

  return envStyles;
};
