import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownList } from 'modules/common/components';
import { useOperatorSelect } from 'modules/common/hooks';

interface OperatorSelectorProps {
  onSelect: (item: string) => void;
  height?: 'm' | 'l';
  className?: string;
  menuItems: string[];
  defaultSelected: string;
  hidden?: boolean;
}

export const OperatorSelector: FC<OperatorSelectorProps> = ({
  onSelect,
  height = 'm',
  className,
  menuItems,
  defaultSelected,
  hidden,
}) => {
  const { t } = useTranslation();

  const { getOperatorName, onlyHasOneOperator } = useOperatorSelect();

  return (
    <DropdownList
      height={height}
      className={className}
      defaultSelected={defaultSelected || t('common.selectOperator')}
      menuItems={menuItems}
      show={getOperatorName}
      onSelect={onSelect}
      disabled={onlyHasOneOperator}
      hidden={hidden}
      id="operatorSelector"
    />
  );
};
