import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const containerSettingsModule = () => import('modules/container-settings');

export const containerSettingsRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.CONTAINER_SETTINGS,
        lazy: lazyImportComponent(containerSettingsModule, 'ContainerSettingsScreen'),
      },
    ],
  },
];
