export const CONTAINER_BOXES_API_URLS = {
  CONTAINER_BOX: '/container-box',
  CONTAINER_BOX_BY_ID: (id: string) => `/container-box/${id}`,
  ADD_NOTE: '/container-box/add-note',
  ACCESS_CODE: (deviceId: string) => `/container-box/${deviceId}/access-code`,
  AUTHORIZE: (deviceId: string) => `/container-box/${deviceId}/authorize`,
  BLOCK: (deviceId: string) => `/container-box/${deviceId}/block`,
  REFRESH_TOKEN: '/container-box/refresh-tokens',
  CONTAINER_BOX_LOG_INFO: '/admin/container-box-log-info',
  KICK_OUT_CONTAINER_ROOM: '/container-box/kick-out-container-room',
  KICK_OUT_WAITING_ROOM: '/container-box/kick-out-waiting-room',
  SEND_CONTAINER_COMMAND: '/container-control/send-container-command',
};
