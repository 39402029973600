import { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'modules/common';
import { useAddNewVendingMachineMutation } from 'modules/vending-machines/api';
import {
  AddNewVendingMachineBody,
  AddNewVendingMachineFields,
  VENDING_MACHINE_STATUS,
} from 'modules/vending-machines/types';
import { AddNewVendingMachineSchema } from 'modules/vending-machines/validation';
import { PATHS } from 'router/utils';

const defaultAddNewVendingMachineFields: AddNewVendingMachineFields = {
  deviceId: '',
  status: VENDING_MACHINE_STATUS.ACTIVE,
  isOpen: false,
  noteData: '',
  containerBoxUId: undefined,
};

export const useAddNewVendingMachine = (
  initialValues: Partial<AddNewVendingMachineFields> = {},
) => {
  const navigate = useNavigate();

  const initialAddNewVendingMachineFields = {
    ...defaultAddNewVendingMachineFields,
    ...initialValues,
  };

  const formHook = useForm<AddNewVendingMachineFields>(
    AddNewVendingMachineSchema,
    initialAddNewVendingMachineFields,
    false,
  );

  const { form, toastFormErrors, formErrors, isFormValid, validate } = formHook;
  const [addNewVendingMachine, { isLoading }] = useAddNewVendingMachineMutation();

  useEffect(() => {
    if (!isFormValid) {
      toastFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const addNewVendingMachineHandler = async (event: FormEvent) => {
    event.preventDefault();
    const errors = await validate();

    if (!errors) {
      const body: AddNewVendingMachineBody = {
        deviceId: form.deviceId,
        status: form.status,
        isOpen: form.isOpen,
        noteData: form.noteData,
        containerBoxUId: form.containerBoxUId,
      };
      const res = await addNewVendingMachine(body).unwrap();
      if (res) {
        navigate(`${PATHS.VENDING_MACHINES}/${form.status}`);
      }
    }
  };

  return {
    addNewVendingMachineHandler,

    formHook,
    isLoading,
  };
};
