import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface NavigationItemProps {
  to: string;
  label: string | null;
}

export const NavigationItem: FC<NavigationItemProps> = ({ to, label }) => {
  const { pathname } = useLocation();

  return (
    <li className="hover:bg-neutral-700 hover:font-bold transition ease-in-out">
      <Link className="p-2 pl-4 block" reloadDocument={to === pathname} to={to}>
        <p>{label}</p>
      </Link>
    </li>
  );
};
