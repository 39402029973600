import { FC } from 'react';
import { Link } from 'react-router-dom';

interface SuspendedScannerProps {
  deviceId: string;
}

export const SuspendedScanner: FC<SuspendedScannerProps> = ({ deviceId }) => {
  return (
    <Link to={`/scanners/detail/${deviceId}`}>
      <div className="font-bold shadow-md hover:shadow-xl transition-all bg-neutral-300 text-center py-2 px-4">
        {deviceId}
      </div>
    </Link>
  );
};
