import { FC, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { setBagsDepositedListPage, setBagsDepositedListSearchAndFilters } from 'modules/activities';
import {
  BAG_STATUSES,
  DATE_RANGE_TYPES,
  calculateDateBack,
  emptySearchAndFilters,
  endOfTheDay,
  startOfTheDay,
  useAppDispatch,
} from 'modules/common';
import { BAGS_WAITNG_LEGEND } from 'modules/dashboard/constants';
import { PATHS } from 'router/utils';

interface BagsWaitingChartProps {
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: string[];
    }[];
  };
}

export const BagsWaitingChart: FC<BagsWaitingChartProps> = ({ data }) => {
  const barRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleNavigation = (legendItem: { text: string }) => {
    let dateRangeType = DATE_RANGE_TYPES.DATE_RANGE;
    let dateRange;
    switch (legendItem.text) {
      case t(`dashboard.legend.${BAGS_WAITNG_LEGEND.ONE_DAY}`):
        dateRangeType = DATE_RANGE_TYPES.TODAY;
        dateRange = {
          startDate: startOfTheDay().toISOString(),
          endDate: endOfTheDay().toISOString(),
        };
        break;
      case t(`dashboard.legend.${BAGS_WAITNG_LEGEND.TWO_DAYS}`):
        dateRange = {
          startDate: calculateDateBack(1).toISOString(),
          endDate: endOfTheDay(calculateDateBack(1)).toISOString(),
        };
        break;
      case t(`dashboard.legend.${BAGS_WAITNG_LEGEND.THREE_DAYS}`):
        dateRange = {
          startDate: calculateDateBack(2).toISOString(),
          endDate: endOfTheDay(calculateDateBack(2)).toISOString(),
        };
        break;

      default:
        dateRange = {
          startDate: undefined,
          endDate: calculateDateBack(3).toISOString(),
        };
        break;
    }
    dispatch(setBagsDepositedListPage(1));
    dispatch(
      setBagsDepositedListSearchAndFilters({
        ...emptySearchAndFilters,
        addFilter: {
          ...emptySearchAndFilters.addFilter,
          dateRangeType,
          dateRange,
          bagsStatus: BAG_STATUSES.DROPPED,
        },
      }),
    );

    return navigate(PATHS.BAGS_DEPOSITED);
  };

  return (
    <Bar
      ref={barRef}
      data={data}
      options={{
        layout: { padding: 1 },
        scales: {
          y: {
            max: 100,
            min: 0,
          },
        },
        plugins: {
          legend: {
            onHover: (event) => {
              if (event.native?.target) {
                // eslint-disable-next-line no-param-reassign
                (event.native.target as HTMLDivElement).style.cursor = 'pointer';
              }
            },
            onLeave(event) {
              if (event.native?.target) {
                // eslint-disable-next-line no-param-reassign
                (event.native.target as HTMLDivElement).style.cursor = 'default';
              }
            },
            onClick(_e, legendItem) {
              handleNavigation(legendItem);
            },
          },
        },
      }}
    />
  );
};
