import { DEFAULT_APLHA_CHANNEL } from 'modules/dashboard';
import { BagsTodayChartData, BagsTodayList } from 'modules/dashboard/types';

export const generateBagsTodayData = (
  { data }: BagsTodayList,
  label: string,
): BagsTodayChartData => {
  const bagsTodayChartData: BagsTodayChartData = {
    labels: [],
    datasets: [
      {
        label,
        data: [],
        backgroundColor: [],
      },
    ],
  };

  data.forEach((location) => {
    bagsTodayChartData.labels.push(location.address.address);
    bagsTodayChartData.datasets[0].data.push(location.totalBagsCollectedToday);
    bagsTodayChartData.datasets[0].backgroundColor?.push(
      `rgba(${location.locationColor}, ${DEFAULT_APLHA_CHANNEL})`,
    );
  });
  return bagsTodayChartData;
};

export const getTotalBags = ({ data }: BagsTodayList): number => {
  return data.reduce((total, item) => total + item.totalBagsCollectedToday, 0);
};
