/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, PropsWithChildren, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import HamburgerIcon from 'assets/icons/hamburger.svg';
import { DropdownMenu, Image } from 'modules/common/components';

interface SubnavigationMenuProps extends PropsWithChildren {
  id?: string;
  img?: string;
  label?: string;
  className?: string;
  dropDownClassName?: string;
}

export const SubnavigationMenu: FC<SubnavigationMenuProps> = ({
  id,
  children,
  img = HamburgerIcon,
  label,
  className = 'w-[60px]',
  dropDownClassName,
}) => {
  const [show, setShow] = useState(false);
  const [isStaysOpen, setStaysOpen] = useState(false);
  const dropDownMenu = useRef(null);

  const toggleShow = () => setShow((previousState) => !previousState);
  const toggleOpen = () => {
    setStaysOpen((previousState) => !previousState);
    setShow(false);
  };

  useOnClickOutside(dropDownMenu, () => {
    setStaysOpen(false);
    setShow(false);
  });

  return (
    <div
      id={id}
      onMouseEnter={toggleShow}
      onMouseLeave={() => setShow(false)}
      ref={dropDownMenu}
      className={`relative flex cursor-pointer justify-center items-center h-full hover:bg-menuHover transition ease-in-out z-30 ${
        isStaysOpen ? 'bg-menuHover' : ''
      } ${className}`}
    >
      <div className="w-full h-full flex justify-center items-center" onClick={toggleOpen}>
        {label && <p className="mx-3">{label}</p>}
        <Image src={img} alt="subNav" className="w-6 m-auto" />
      </div>

      {(show || isStaysOpen) && (
        <DropdownMenu className={dropDownClassName}>{children}</DropdownMenu>
      )}
    </div>
  );
};

export { NavigationItem } from './NavigationItem';
