/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import {
  CONTAINER_BOXES_LIST_SORT_OPTION_TABLE_KEYS,
  ContainerBoxesListState,
} from 'modules/container-boxes/types';

const initialState: ContainerBoxesListState = {
  page: 1,
  sorting: { sort: CONTAINER_BOXES_LIST_SORT_OPTION_TABLE_KEYS.IN_SERVICE, order: ORDER.DESC },
};

export const containerBoxesListSlice = createSlice({
  name: 'containerBoxesList',
  initialState,
  reducers: {
    setContainerBoxesListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setContainerBoxesListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const containerBoxesListReducer = containerBoxesListSlice.reducer;

export const { setContainerBoxesListSorting, setContainerBoxesListPage } =
  containerBoxesListSlice.actions;

export const selectContainerBoxList = (state: RootState) => state.containerBoxesList;
