import {
  addMinutes,
  startOfToday,
  format,
  parse,
  differenceInMinutes,
  intervalToDuration,
} from 'date-fns';

/**
 * Converts from number of minutes since the start of the day to formatted time of day.
 * Used for displaying location opening times. HH:mm format is required for HTML input type "time"!
 * @see OpeningHours, Locations
 * @param minutes number of minutes from start of day.
 * @param formatString date-fns format string.
 * @returns formatted time of day.
 */
export function formatMinutesFromDayStart(
  minutes?: number,
  formatString?: string,
): string | undefined {
  if (minutes === undefined) {
    return undefined;
  }
  const date = startOfToday();
  return format(addMinutes(date, minutes), formatString ?? 'HH:mm');
}

/**
 * Converts a HH:mm format time string to minutes since the start of the day.
 * Used for storing location opening times. HH:mm format is required for HTML input type "time"!
 * @param input time of day in HH:mm format
 * @returns minutes since the start of the day
 */
export function timeStringToMinutesFromDayStart(input?: string): number | undefined {
  if (input === undefined) {
    return undefined;
  }
  const baseDate = startOfToday();
  const parsed = parse(input, 'HH:mm', baseDate);
  return differenceInMinutes(parsed, startOfToday(), { roundingMethod: 'trunc' });
}

/**
 * Converts a string from Date.parse compatible format (for example, ISO string) to a custom format with date-fns
 * @param inputDate Date string
 * @param formatString date-fns format string (default: yyyy-MM-dd)
 * @returns Formatted date
 */
export function reformatDateWithFormatString(inputDate: string, formatString?: string): string {
  const parsed = Date.parse(inputDate);
  return format(parsed, formatString ?? 'yyyy-MM-dd');
}

/**
 * Attempts to parse and reformat a date given in yyyy-MM-dd format to an ISO date.
 * @param input Date in yyyy-MM-dd format
 * @returns The date in ISO format
 */
export function reformatDateToISO(input?: string): string | undefined {
  if (input === undefined) return undefined;
  const parsed = parse(input, 'yyyy-MM-dd', startOfToday());
  if (Number.isNaN(parsed.getTime())) return undefined;
  return parsed.toISOString();
}

/**
 * Formats a duration given in seconds into a string in the format 'HH:mm:ss' or 'mm:ss' if the duration is less than an hour.
 * @param seconds The duration in seconds.
 * @returns The formatted duration string.
 */
export const formatSecondsToDuration = (seconds: number) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  const formattedSeconds = duration.seconds?.toString().padStart(2, '0') || '00';
  const formattedMinutes = duration.minutes?.toString().padStart(2, '0') || '00';
  let formattedDuration = `${formattedMinutes}:${formattedSeconds}`;

  if (duration.hours) {
    const formattedHours = duration.hours.toString().padStart(2, '0');
    formattedDuration = `${formattedHours}:${formattedDuration}`;
  }

  return formattedDuration;
};
