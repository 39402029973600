import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GROUP_ROLE_ACCESS,
  USER_ROLE_KEYS,
  selectIsSuperuser,
  useHasGroupAccess,
} from 'modules/auth';
import { CERTIFICATE_STATUSES } from 'modules/certificates';
import { NavigationItem, SubnavigationMenu } from 'modules/common/components';
import { useAppSelector } from 'modules/common/hooks';
import { PATHS } from 'router/utils';

export const SubMenu: FC = () => {
  const { t } = useTranslation();
  const isSuper = useAppSelector(selectIsSuperuser);
  const hasGroupAccess = useHasGroupAccess();

  return (
    <SubnavigationMenu>
      <ul className="pt-8 pb-4 text-sm">
        {hasGroupAccess(USER_ROLE_KEYS.USER_MODIFICATION, GROUP_ROLE_ACCESS.READ) && (
          <NavigationItem to={PATHS.USERS} label={t('subnavigation.userManagement')} />
        )}
        <NavigationItem to={PATHS.STATE_RATES} label={t('subnavigation.prices')} />
        {isSuper && (
          <>
            <NavigationItem
              to={`${PATHS.PROCESSING_FEE}`}
              label={t('subnavigation.processingFee')}
            />
            <NavigationItem
              to={`${PATHS.SUPPORT_CONTACT}`}
              label={t('subnavigation.supportContact')}
            />
          </>
        )}
        {hasGroupAccess(USER_ROLE_KEYS.SEND_CUSTOM_NOTIFICATION, GROUP_ROLE_ACCESS.READ) && (
          <NavigationItem
            to={PATHS.SEND_NOTIFICATIONS}
            label={t('subnavigation.notificationSending')}
          />
        )}
        {isSuper && (
          <NavigationItem
            to={`${PATHS.CERTIFICATES}/${CERTIFICATE_STATUSES.ACTIVE}`}
            label={t('subnavigation.certificates')}
          />
        )}
        {isSuper && (
          <NavigationItem
            to={PATHS.CONTAINER_SETTINGS}
            label={t('subnavigation.containerSettings')}
          />
        )}
        {isSuper && (
          <NavigationItem to={`${PATHS.BAG_ROLL_PRICE}`} label={t('subnavigation.bagRollPrice')} />
        )}
        {/* TODO IP RESTRICTION Put back once relevant, removed for the time being */}
        {/* {isSuper && (
          <NavigationItem to={PATHS.IP_ADDRESSES} label={t('subnavigation.ipAddresses')} />
        )} */}
      </ul>
    </SubnavigationMenu>
  );
};
