import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'usehooks-ts';

import { formatSecondsToDuration } from 'modules/common';
import { DOOR_STATUS } from 'modules/container-boxes';
import { ScanningDeviceOnContainerBoxDashboard } from 'modules/dashboard/types';

interface DoorCardProps {
  door: ScanningDeviceOnContainerBoxDashboard;
  index: number;
}

export const DoorCard: FC<DoorCardProps> = ({ door, index }) => {
  const { t } = useTranslation();

  const mockedTimer = 100; // TODO QD-1676. There's no timer yet. this is just a placeholder (probably not going to be in seconds, but as a date)
  const mockedStatus = DOOR_STATUS.OK; // TODO QD-1726

  // Capacity is not used as of right now. Leaving it here in case it has to be added
  const capacityFull = 0;

  const [secondsRemaining, setSecondsRemaining] = useState(mockedTimer);

  const isIndexEven = index % 2 === 0;

  useEffectOnce(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(intervalId);
  });

  const formattedSecondRemaining = formatSecondsToDuration(secondsRemaining);

  return (
    <div className={`flex ${isIndexEven ? 'flex-row' : 'flex-row-reverse'}`}>
      <div className={`${isIndexEven ? 'border-l' : 'border-r'} p-4 flex-grow`}>
        <p className="text-center font-bold">
          {t('containerBoxes.door')} {index}:
        </p>
        <div className="text-sm text-neutral-600">
          <div className="flex justify-center">
            <p
              className={`text-sm rounded px-3 inline-block ${
                mockedStatus === DOOR_STATUS.OK ? 'bg-neutral-200' : 'bg-red-400 text-white'
              }`}
            >
              {t(`containerBoxes.${mockedStatus}`)}
            </p>
          </div>
          {door.ongoingBagSession && (
            <>
              <p>
                {door.ongoingBagSession?.user.firstName} {door.ongoingBagSession?.user.lastName}
              </p>
              <p>
                {t('dashboard.nBagsDeposited', {
                  numberOfBags: door.bagsDroppedInOngoingSession,
                })}
              </p>
              <p>
                {t('dashboard.timer')}: {formattedSecondRemaining}
              </p>
            </>
          )}
        </div>
      </div>
      <div className="relative flex-none w-2 border">
        <div
          className={`absolute bottom-0 w-full ${
            capacityFull >= 0.8 ? 'bg-red-400' : 'bg-neutral-400'
          }`}
          style={{ height: `${capacityFull * 100}%` }}
        />
      </div>
    </div>
  );
};
