import { useNavigate } from 'react-router-dom';

import {
  useLoginMutation,
  useLazyWhoAmIQuery,
  useLogoutMutation,
  useTwoFactorAuthRequestMutation,
} from 'modules/auth/api';
import { setCredentials, login as localLogin, setStayLoggedIn } from 'modules/auth/store';
import { LoginBody } from 'modules/auth/types';
import { useAppDispatch } from 'modules/common';
import { PATHS } from 'router/utils';

export const useAuth = () => {
  const navigate = useNavigate();

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [whoAmI, { isLoading: isWhoAmILoading }] = useLazyWhoAmIQuery();
  const [logout, { isLoading: isLogoutLoading }] = useLogoutMutation();
  const [sendTwoFactorAuthRequest, { isLoading: isLoadingTwoFactorAuthRequest }] =
    useTwoFactorAuthRequestMutation();
  const dispatch = useAppDispatch();

  const isLoading =
    isLoginLoading || isWhoAmILoading || isLogoutLoading || isLoadingTwoFactorAuthRequest;

  const handleLogout = async () => {
    try {
      await logout().unwrap();
      navigate(PATHS.LOGIN);
    } catch {
      // error is handled in config api
    }
  };

  const handleWhoAmI = async () => {
    try {
      await whoAmI().unwrap();
      dispatch(localLogin());
    } catch {
      handleLogout();
    }
  };

  const handleLogin = async (form: LoginBody) => {
    try {
      const loginData = await login(form).unwrap();
      dispatch(setCredentials(loginData));
      dispatch(setStayLoggedIn(form.stayLoggedIn));
      handleWhoAmI();
    } catch {
      // error handled in config api
    }
  };

  return { isLoading, handleLogin, handleLogout, sendTwoFactorAuthRequest };
};
