import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const containerBoxesModule = () => import('modules/container-boxes');

export const containerBoxesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.CONTAINER_BOXES_LIST,
        lazy: lazyImportComponent(containerBoxesModule, 'ContainerBoxes'),
      },
      {
        path: PATHS.CONTAINER_BOX_DETAILS_BY_ID,
        lazy: lazyImportComponent(containerBoxesModule, 'ContainerBoxDetails'),
      },
      {
        path: PATHS.ADD_NEW_CONTAINER_BOX,
        lazy: lazyImportComponent(containerBoxesModule, 'NewContainerBox'),
      },
    ],
  },
];
