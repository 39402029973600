import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from 'modules/common/components';

interface MessageBoxProps {
  text: string;
  onClick: () => void;
  isOpen: boolean;
  title?: string | null;
  messageClassName?: string;
  boxClassName?: string;
  buttonLabel?: string | null;
}

export const MessageBox: FC<MessageBoxProps> = ({
  text,
  onClick,
  isOpen,
  title,
  messageClassName = 'mt-10 ml-5 text-neutral-900 whitespace-pre-line',
  boxClassName = 'h-[120px] w-[400px]',
  buttonLabel,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} title={title} className={boxClassName}>
      <p className={messageClassName}>{text}</p>
      <Button
        className="w-[60px] h-[30px] absolute right-3 bottom-3 ml-auto bg-neutral-500 text-white hover:font-bold hover:bg-neutral-700"
        type="button"
        onClick={onClick}
        label={buttonLabel || t('common.done')}
      />
    </Modal>
  );
};
