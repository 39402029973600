/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { BagRollPriceLogState } from 'modules/bag-roll-price/types';

import { bagRollPriceApi } from '../api';

const initialState: BagRollPriceLogState = {
  page: 1,
  lastPage: 1,
  bagRollPriceLogs: [],
};

export const bagRollPriceLogsSlice = createSlice({
  name: 'bagRollPriceLogs',
  initialState,
  reducers: {
    resetBagRollPriceLogs: (state) => {
      state.page = 1;
      state.bagRollPriceLogs = [];
    },
    setBagRollPriceLogsPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      bagRollPriceApi.endpoints.getBagRollPriceLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.lastPage = payload.meta.lastPage;
          state.bagRollPriceLogs = state.bagRollPriceLogs.concat(payload.data);
        } else {
          state.lastPage = payload.meta.lastPage;
          state.bagRollPriceLogs = payload.data;
        }
      },
    );
  },
});

export const bagRollPriceLogsReducer = bagRollPriceLogsSlice.reducer;

export const { setBagRollPriceLogsPage, resetBagRollPriceLogs } = bagRollPriceLogsSlice.actions;

export const selectBagRollPriceLogs = (state: RootState) => state.bagRollPriceLogs.bagRollPriceLogs;

export const selectBagRollPriceLogsPage = (state: RootState) => state.bagRollPriceLogs.page;

export const selectBagRollPriceLogsLastPage = (state: RootState) => state.bagRollPriceLogs.lastPage;
