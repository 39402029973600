import { selectOperators, selectSelectedOperator } from 'modules/auth';
import { useAppSelector } from 'modules/common/hooks';

export const useOperatorSelect = () => {
  const operators = useAppSelector(selectOperators);
  const selectedOperatorAuth = useAppSelector(selectSelectedOperator);
  const onlyHasOneOperator = operators?.length === 1;

  const operatorDropdownItems = operators.map(({ organizationUId }) => organizationUId);

  const operatorUIdList = operators.map(({ organizationUId }) => organizationUId);

  const getOperatorById = (item: string | null) =>
    operators.find(({ organizationUId }) => organizationUId === item);

  const getOperatorName = (item: string | null) => getOperatorById(item)?.name || item || '';

  return {
    operators,
    selectedOperatorAuth,
    operatorUIdList,
    operatorDropdownItems,
    getOperatorById,
    getOperatorName,
    onlyHasOneOperator,
  };
};
