import { LogInfo, NoteData, ORDER, PageInfo, SCANNING_DEVICE_TYPE, Sorting } from 'modules/common';
import { AddNewLocationResponse } from 'modules/locations';

export enum OPERATOR_STATUSES {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVED = 'archived',
}

export interface OperatorItem {
  organizationUId: string;
  email: string;
  address: string;
  name: string;
  mainContactName: string;
  group: string;
  position: string;
  phone: string;
  createdAt: string;
  totalBagScanner: number;
  totalLocations: number;
}

export enum SCANNING_DEVICE_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export interface ScanningDeviceItem {
  _id: string;
  deviceId: string;
  accessCode: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  blockedAt: string | null;
  history: [];
  status: SCANNING_DEVICE_STATUS;
  certificate: string;
  createdAt: string;
  updatedAt: string;
}
export interface OperatorResponse {
  _id: string;
  name: string;
  organizationUId: string;
  address: string;
  mainContactName: string;
  phone: string;
  email: string;
  status: OPERATOR_STATUSES;
  scanningDevices: ScanningDeviceItem[];
  locations: AddNewLocationResponse[];
  createdAt: string;
  noteData?: NoteData;
}

export interface UpdateOperatorBody {
  name?: string;
  status?: OPERATOR_STATUSES;
  mainContactName?: string;
  phone?: string;
  email?: string;
  noteData?: NoteData;
}

export interface OperatorPatchRequest extends UpdateOperatorBody {
  id: string;
}

export interface OperatorsList {
  meta: PageInfo;
  data: OperatorItem[];
}

export enum OPERATORS_LIST_SORT_OPTION_TABLE_KEYS {
  SIGNED_UP = 'signedUp',
}
export enum OPERATORS_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
}

export type OperatorsListSorting = Sorting<OPERATORS_LIST_SORT_OPTION_TABLE_KEYS>;

export interface OperatorsListState {
  page: number;
  sorting: OperatorsListSorting;
}

export const operatorsListSortOptions: Record<
  OPERATORS_LIST_SORT_OPTION_TABLE_KEYS,
  OPERATORS_LIST_SORT_OPTIONS
> = {
  signedUp: OPERATORS_LIST_SORT_OPTIONS.CREATED_AT,
};

export type OperatorsListQueryParams = {
  page?: number;
  sort?: OPERATORS_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: string;
};

export enum ADD_OPERATOR_FIELDS {
  NAME = 'name',
  ADDRESS = 'address',
  MAIN_CONTACT = 'mainContactName',
  PHONE = 'phone',
  EMAIL = 'email',
  NOTE_DATA = 'noteData',
}

export interface OperatorFields {
  name: string;
  address: string;
  mainContactName: string;
  phone: string;
  email: string;
  noteData?: string;
}

export interface AddNewOperatorResponse {
  _id: string;
  name: string;
}

export interface OperatorLogInfo extends LogInfo<OperatorResponse> {
  id: string;
}

export interface OperatorLogInfoList {
  data: OperatorLogInfo[];
  meta: PageInfo;
}

export interface OperatorLogsListState {
  page: number;
  operatorLogs: OperatorLogInfo[];
}
