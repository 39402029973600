import { quickDropAdminApi } from 'config/api';
import { BAG_ROLL_PRICE_API_URLS } from 'modules/bag-roll-price/constants';
import { resetBagRollPriceLogs, setBagRollPriceLogsPage } from 'modules/bag-roll-price/store';
import {
  BagRollPriceChange,
  BagRollPrice,
  BagRollPriceLogList,
  BagRollPriceLogInfoQueryParams,
} from 'modules/bag-roll-price/types';
import { REQUEST_METHODS } from 'modules/common';

export const bagRollPriceApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['BagRollPriceLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCurrentBagRollPrice: builder.query<BagRollPrice, void>({
        query: () => ({
          url: BAG_ROLL_PRICE_API_URLS.BASE_URL,
          method: REQUEST_METHODS.GET,
        }),
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      addNewBagRollPrice: builder.mutation<BagRollPrice, BagRollPriceChange>({
        query: (body) => ({
          body,
          url: BAG_ROLL_PRICE_API_URLS.BASE_URL,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['BagRollPriceLogInfo']),
        onQueryStarted(newPriceChange, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            bagRollPriceApi.util.updateQueryData('getCurrentBagRollPrice', undefined, () => {
              return { price: newPriceChange.price, createdAt: new Date().toISOString() };
            }),
          );
          queryFulfilled
            .then(() => {
              dispatch(setBagRollPriceLogsPage(1));
              dispatch(resetBagRollPriceLogs());
            })
            .catch(updatedResult.undo);
        },
      }),
      getBagRollPriceLogInfo: builder.query<BagRollPriceLogList, BagRollPriceLogInfoQueryParams>({
        query: ({ page }) => {
          const params = {
            'pagination[page]': page,
          };
          return {
            url: `${BAG_ROLL_PRICE_API_URLS.BASE_URL}${BAG_ROLL_PRICE_API_URLS.GET_INFO}`,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['BagRollPriceLogInfo']),
      }),
    }),
  });

export const {
  useGetCurrentBagRollPriceQuery,
  useAddNewBagRollPriceMutation,
  useLazyGetBagRollPriceLogInfoQuery,
} = bagRollPriceApi;
