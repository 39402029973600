import { FormEvent, useEffect } from 'react';

import { useForm } from 'modules/common';
import { useSendNotificationMutation } from 'modules/notifications/api';
import { SEND_TO_TYPE, SendNotificationFields } from 'modules/notifications/types';
import { SendNotificationsSchema } from 'modules/notifications/validation';

const defaultSendNotificationFields: SendNotificationFields = {
  sendToType: SEND_TO_TYPE.EVERY_CUSTOMER,
  titleText: '',
  bodyText: '',
  platformType: null,
  acceptText: '',
};

export const useSendNotification = () => {
  const initialSendNotificationFields = { ...defaultSendNotificationFields };

  const formHook = useForm<SendNotificationFields>(
    SendNotificationsSchema,
    initialSendNotificationFields,
    false,
  );

  const { form, toastFormErrors, formErrors, isFormValid, validate } = formHook;
  const [sendNotification, { isLoading }] = useSendNotificationMutation();

  useEffect(() => {
    if (!isFormValid) {
      toastFormErrors();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const validateSendNotificationForm = async (event: FormEvent) => {
    event.preventDefault();
    return validate();
  };

  const sendNotificationHandler = async () => {
    if (isFormValid) {
      await sendNotification(form).unwrap();
      formHook.setForm(initialSendNotificationFields);
    }
  };

  return { sendNotificationHandler, formHook, isLoading, validateSendNotificationForm };
};
