import { quickDropAdminApi } from 'config/api';
import i18n from 'config/translation';
import {
  CommentPostRequest,
  LogInfoQueryParams,
  REGEX_OPTIONS,
  REQUEST_METHODS,
} from 'modules/common/types';
import { toastSuccessOrWarn } from 'modules/common/utils';
import { CONTAINER_BOXES_API_URLS } from 'modules/container-boxes/constants';
import {
  AddNewContainerBoxBody,
  AddNewContainerBoxResponse,
  ContainerBoxLogInfo,
  ContainerBoxLogInfoList,
  ContainerBoxesList,
  ContainerBoxesListQueryParams,
  CONTAINER_BOX_STATUS,
  UpdateContainerBoxBody,
  UpdateContainerBoxResponse,
  ContainerBoxDetailsData,
  KickOffContainerRoomBody,
  KickOffWaitingRoomBody,
  WaitingTicketItem,
  ContainerCommandRequestDTO,
  ContainerCommandDTO,
} from 'modules/container-boxes/types';

export const containerBoxesApi = quickDropAdminApi
  .enhanceEndpoints({
    addTagTypes: ['ContainerBoxes', 'Location', 'ContainerBoxLogInfo'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getContainerBoxes: builder.query<ContainerBoxesList, ContainerBoxesListQueryParams>({
        query: ({
          page,
          sort,
          order,
          status,
          operatorUId,
          take,
          containerBoxUIdSearch,
          filters,
        }) => {
          const params = {
            'pagination[page]': page,
            'pagination[take]': take,
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': operatorUId,
            'filters[containerBoxUId][$regex]': containerBoxUIdSearch || undefined,
            'filters[containerBoxUId][$options]': containerBoxUIdSearch
              ? REGEX_OPTIONS.CASE_INSENSITIVE
              : undefined,
            ...filters,
          };

          return {
            url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['ContainerBoxes']),
      }),
      getContainerBox: builder.query<ContainerBoxDetailsData, string>({
        query: (id: string) => ({
          url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX_BY_ID(id),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (result) => (!result ? [] : ['ContainerBoxes']),
      }),
      updateContainerBox: builder.mutation<UpdateContainerBoxResponse, UpdateContainerBoxBody>({
        query: (body) => {
          const { id, locationForRTK, ...rest } = body;
          return {
            body: rest,
            url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX_BY_ID(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        invalidatesTags: (_result, error, args) => {
          const tagsToInvalidate: ('ContainerBoxes' | 'Location' | 'ContainerBoxLogInfo')[] = [];
          if (!error) {
            tagsToInvalidate.push('ContainerBoxes', 'ContainerBoxLogInfo');
            if (args.locationUId) {
              tagsToInvalidate.push('Location');
            }
          }
          return tagsToInvalidate;
        },
        async onQueryStarted({ id, ...update }, { dispatch, queryFulfilled }) {
          const updatedResult = dispatch(
            containerBoxesApi.util.updateQueryData('getContainerBox', id, (results) => {
              const { locationUId, locationForRTK, ...rest } = update;
              if (locationUId && locationForRTK) {
                return {
                  ...results,
                  ...rest,
                  locationUId,
                  locationAddress: locationForRTK.address,
                  locationCity: locationForRTK.city,
                  locationZip: locationForRTK.zipCode,
                };
              }
              return { ...results, ...rest };
            }),
          );

          try {
            await queryFulfilled;
          } catch {
            updatedResult.undo();
          }
        },
      }),
      addNewContainerBox: builder.mutation<AddNewContainerBoxResponse, AddNewContainerBoxBody>({
        query: (body) => ({
          url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['ContainerBoxes', 'Location']),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const createdContainerBox = await queryFulfilled;

            const isLocationUIdEmpty = args.locationUId === '';
            const desiredToBeActive = args.status === CONTAINER_BOX_STATUS.ACTIVE;
            const backendChangedStatusToSuspended =
              desiredToBeActive &&
              createdContainerBox.data.status === CONTAINER_BOX_STATUS.SUSPENDED;

            toastSuccessOrWarn({
              isWarning: isLocationUIdEmpty && backendChangedStatusToSuspended,
              warningMsg: i18n.t('containerBoxes.noLocationWarningMsg'),
            });
          } catch {
            // error handled in config api
          }
        },
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      getContainerBoxLogInfo: builder.query<ContainerBoxLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[containerBoxUId]': id,
          };
          return {
            url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['ContainerBoxLogInfo']),
      }),
      addContainerBoxComment: builder.mutation<ContainerBoxLogInfo, CommentPostRequest>({
        query: ({ id, comment }) => ({
          url: CONTAINER_BOXES_API_URLS.CONTAINER_BOX_LOG_INFO,
          body: { containerBoxUId: id, comment },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['ContainerBoxLogInfo']),
      }),
      kickOffWaitingRoom: builder.mutation<WaitingTicketItem, KickOffWaitingRoomBody>({
        query: (body) => ({
          url: CONTAINER_BOXES_API_URLS.KICK_OUT_WAITING_ROOM,
          body,
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['ContainerBoxes']),
      }),
      kickOffContainerRoom: builder.mutation<null, KickOffContainerRoomBody>({
        query: (body) => ({
          url: CONTAINER_BOXES_API_URLS.KICK_OUT_CONTAINER_ROOM,
          body,
          method: REQUEST_METHODS.POST,
        }),
      }),
      sendContainerCommand: builder.mutation<ContainerCommandDTO, ContainerCommandRequestDTO>({
        query: (body) => ({
          url: CONTAINER_BOXES_API_URLS.SEND_CONTAINER_COMMAND,
          body,
          method: REQUEST_METHODS.POST,
        }),
      }),
    }),
  });

export const {
  useGetContainerBoxesQuery,
  useGetContainerBoxQuery,
  useKickOffWaitingRoomMutation,
  useKickOffContainerRoomMutation,
  useUpdateContainerBoxMutation,
  useAddNewContainerBoxMutation,
  useLazyGetContainerBoxLogInfoQuery,
  useAddContainerBoxCommentMutation,
  useSendContainerCommandMutation,
} = containerBoxesApi;
