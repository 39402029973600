import { useEffect, useState } from 'react';

import {
  getRefreshToken,
  getRefreshTokenExpiresAtInMs,
  selectStayLoggedIn,
  useAuth,
  useRefreshTokensManuallyMutation,
} from 'modules/auth';
import { useAppSelector } from 'modules/common/hooks';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export const useAutoLogout = () => {
  const refreshTokenExpiresAtInMs = useAppSelector(getRefreshTokenExpiresAtInMs);
  const refreshToken = useAppSelector(getRefreshToken);
  const stayLoggedIn = useAppSelector(selectStayLoggedIn);

  const [remainingTimeInMs, setRemainingTimeInMs] = useState<number | null>();

  const { handleLogout } = useAuth();
  const [refreshTokensManually] = useRefreshTokensManuallyMutation();

  const reauth = async () => {
    try {
      setRemainingTimeInMs(null);
      await refreshTokensManually({ refreshToken, stayLoggedIn }).unwrap();
    } catch {
      // error handled in config api
    }
  };

  useEffect(() => {
    setRemainingTimeInMs(null);

    if (refreshTokenExpiresAtInMs) {
      const remainingTimeTillLogoutInMs = () => refreshTokenExpiresAtInMs - new Date().getTime();

      if (remainingTimeTillLogoutInMs() < 1) {
        handleLogout();
        return () => {};
      }

      const desiredTimeToWarn =
        refreshTokenExpiresAtInMs - new Date().getTime() - FIVE_MINUTES_IN_MS;

      const createWarningTimeout = () =>
        setTimeout(
          () => {
            setRemainingTimeInMs(remainingTimeTillLogoutInMs());
          },
          desiredTimeToWarn > 0 ? desiredTimeToWarn : 0,
        );

      const createLogoutTimeout = () =>
        setTimeout(() => {
          handleLogout();
        }, remainingTimeTillLogoutInMs());

      const timeoutLogout = createLogoutTimeout();
      const timeoutWarning = createWarningTimeout();

      return () => {
        clearTimeout(timeoutLogout);
        clearTimeout(timeoutWarning);
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTokenExpiresAtInMs]);

  return { remainingTimeInMs, reauth };
};
