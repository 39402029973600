import { RouteObject } from 'react-router-dom';

import { ProtectedRoute } from 'router/ProtectedRoute';
import { PATHS, lazyImportComponent } from 'router/utils';

const vendingMachinesModule = () => import('modules/vending-machines');

export const vendingMachinesRouter: RouteObject[] = [
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: PATHS.VENDING_MACHINES_LIST,
        lazy: lazyImportComponent(vendingMachinesModule, 'VendingMachines'),
      },
      {
        path: PATHS.VENDING_MACHINE_DETAILS_BY_ID,
        lazy: lazyImportComponent(vendingMachinesModule, 'VendingMachineDetails'),
      },
      {
        path: PATHS.ADD_NEW_VENDING_MACHINE,
        lazy: lazyImportComponent(vendingMachinesModule, 'NewVendingMachine'),
      },
    ],
  },
];
