import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ContainerBoxForDashboard } from 'modules/dashboard/types';
import { PATHS } from 'router/utils';

import { DoorCard } from './DoorCard';

interface ActiveContainerBoxProps {
  containerBox: ContainerBoxForDashboard;
  index: number;
}

export const ActiveContainerBox: FC<ActiveContainerBoxProps> = ({ containerBox, index }) => {
  const { t } = useTranslation();

  return (
    <Link className="w-[400px]" to={`${PATHS.CONTAINER_BOX_DETAILS}/${containerBox._id}`}>
      <div className="w-96 mt-4 min-h-[12rem] shadow-md h-min-2 hover:shadow-xl transition-all flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="bg-neutral-200 py-1 px-3 border border-neutral-400 text-neutral-400 text-sm font-light">
            {index}
          </div>
          <span className="text-neutral-400 text-sm font-light pr-2 pt-1">{containerBox.name}</span>
        </div>
        <div className="grid grid-cols-2">
          {containerBox.scanningDevices.map((door, doorIndex) => (
            <DoorCard key={door.deviceId} door={door} index={doorIndex + 1} />
          ))}
        </div>
        <div className="text-center mb-2">
          <span>
            {t('dashboard.inQueue')}: <span className="font-bold">{containerBox.inQueue}</span>
          </span>
        </div>
      </div>
    </Link>
  );
};
