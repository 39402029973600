/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, MouseEventHandler } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Image } from 'modules/common/components';

interface HeaderItemProps {
  id?: string;
  img: string;
  title: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  to?: string;
}

export const HeaderItem: FC<HeaderItemProps> = ({
  id,
  img,
  onClick,
  className = '',
  title,
  to,
}) => {
  const { pathname } = useLocation();

  const indicateIfCurrentPath =
    pathname.split('/')[1] && to?.includes(pathname.split('/')[1])
      ? 'bg-menuHover '
      : 'hover:bg-menuHover ';

  const styling = `h-[60px] flex items-center flex-col p-[10px] transition ease-in-out cursor-pointer ${className} ${indicateIfCurrentPath}`;

  return (
    <Link reloadDocument={to === pathname} to={{ pathname: to }} id={id}>
      <div className={styling} onClick={onClick}>
        {title && <p className="text-sm">{title}</p>}
        <Image src={img} alt={title} className="resize-x overflow-hidden" />
      </div>
    </Link>
  );
};
