import { FC } from 'react';

interface FooterProps {
  text?: string;
  className?: string;
}

export const Footer: FC<FooterProps> = ({ text, className }) => {
  return (
    <div
      className={`flex fixed bottom-0 h-footer min-w-full items-center justify-center ${
        className ?? ''
      }`}
    >
      <p>{text}</p>
    </div>
  );
};
