import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import {
  DASHBOARD_API_URLS,
  updateBagsTodayTime,
  updateBagsWaitingTime,
  updateScannersTime,
} from 'modules/dashboard';
import {
  ActiveScannerList,
  BagsTodayList,
  BagsWaitingResponse,
  ContainerBoxesOnDashboard,
} from 'modules/dashboard/types';

export const dashboardApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['Dashboard'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getTodayBags: builder.query<BagsTodayList, undefined>({
        query: () => {
          updateBagsTodayTime();
          return {
            url: DASHBOARD_API_URLS.GET_BAGS_TODAY,
            method: REQUEST_METHODS.GET,
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          if (data.data) {
            // `onStart` side-effect
            dispatch(updateBagsTodayTime());
          }
        },
        providesTags: (result) => (!result ? [] : ['Dashboard']),
      }),
      getScanners: builder.query<ActiveScannerList, undefined>({
        query: () => {
          return {
            url: DASHBOARD_API_URLS.GET_SCANNERS,
            method: REQUEST_METHODS.GET,
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          if (data.data) {
            // `onStart` side-effect
            dispatch(updateScannersTime());
          }
        },
      }),
      getDashboardContainerBoxes: builder.query<ContainerBoxesOnDashboard, void>({
        query: () => {
          return {
            url: DASHBOARD_API_URLS.GET_CONTAINER_BOXES,
            method: REQUEST_METHODS.GET,
          };
        },
      }),
      getBagsWaiting: builder.query<BagsWaitingResponse, undefined>({
        query: () => {
          return {
            url: DASHBOARD_API_URLS.BAGS_WAITING,
            method: REQUEST_METHODS.GET,
          };
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const { data } = await queryFulfilled;
          if (data) {
            // `onStart` side-effect
            dispatch(updateBagsWaitingTime());
          }
        },
      }),
    }),
  });

export const {
  useGetTodayBagsQuery,
  useGetScannersQuery,
  useGetDashboardContainerBoxesQuery,
  useGetBagsWaitingQuery,
} = dashboardApi;
