export const DASHBOARD_API_URLS = {
  GET_BAGS_TODAY: '/recycle/dropoff/bags-today',
  GET_SCANNERS: '/admin/dashboard/scanners',
  GET_CONTAINER_BOXES: '/admin/dashboard/container-boxes',
  BAGS_WAITING: '/admin/dashboard/bags-waiting',
};

export const DEFAULT_APLHA_CHANNEL = '0.4';

export const BAGS_WAITNG_LEGEND = {
  ONE_DAY: '1day',
  TWO_DAYS: '2days',
  THREE_DAYS: '3days',
  FOUR_OR_MORE_DAYS: '4+days',
};
