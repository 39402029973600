import { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForm } from 'modules/common';
import { useAddNewContainerBoxMutation } from 'modules/container-boxes/api';
import {
  ADD_CONTAINER_BOX_FIELDS,
  AddNewContainerBoxBody,
  AddNewContainerBoxFields,
  CONTAINER_BOX_STATUS,
} from 'modules/container-boxes/types';
import { AddNewContainerBoxSchema } from 'modules/container-boxes/validation';
import { PATHS } from 'router/utils';

const defaultAddNewContainerBoxFields: AddNewContainerBoxFields = {
  name: '',
  organizationUId: undefined,
  locationUId: '',
  status: CONTAINER_BOX_STATUS.ACTIVE,
  deviceIds: [],
};

export const useAddNewContainerBox = (initialValues: Partial<AddNewContainerBoxFields> = {}) => {
  const navigate = useNavigate();

  const inputFieldsArray = [
    ADD_CONTAINER_BOX_FIELDS.NAME,
    ADD_CONTAINER_BOX_FIELDS.LOCATION_UID,
    ADD_CONTAINER_BOX_FIELDS.STATUS,
  ];

  const initialAddNewContainerBoxFields = { ...defaultAddNewContainerBoxFields, ...initialValues };

  const formHook = useForm<AddNewContainerBoxFields>(
    AddNewContainerBoxSchema,
    initialAddNewContainerBoxFields,
    false,
  );

  const { form, toastFormErrors, formErrors, isFormValid, validate } = formHook;
  const [addNewContainerBox, { isLoading }] = useAddNewContainerBoxMutation();

  useEffect(() => {
    if (!isFormValid) {
      toastFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const addNewContainerBoxHandler = async (event: FormEvent) => {
    event.preventDefault();
    const errors = await validate();

    if (!errors) {
      const body: AddNewContainerBoxBody = {
        ...form,
      };
      const res = await addNewContainerBox(body).unwrap();
      if (res) {
        navigate(`${PATHS.CONTAINER_BOXES}/${form.status}`);
      }
    }
  };

  return {
    addNewContainerBoxHandler,
    inputFieldsArray,
    formHook,
    isLoading,
  };
};
