import { quickDropAdminApi } from 'config/api';
import { REQUEST_METHODS } from 'modules/common';
import { CONTAINER_SETTINGS_API_URLS } from 'modules/container-settings/constants';
import {
  ContainerSettingsChange,
  ContainerSettingsLogInfoQueryParams,
  ContainerSettingsLogList,
  ContainerTimeout,
  ContainerTimeoutList,
} from 'modules/container-settings/types';

export const containerSettingsApi = quickDropAdminApi
  .enhanceEndpoints({ addTagTypes: ['ContainerSettings', 'ContainerSettingsLogInfo'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCurrentContainerTimeouts: builder.query<ContainerTimeout[], void>({
        query: () => ({
          url: CONTAINER_SETTINGS_API_URLS.BASE_URL,
          method: REQUEST_METHODS.GET,
        }),
        transformResponse: (data: ContainerTimeoutList) =>
          Object.keys(data)
            .map((key) => data[+key])
            .sort((a, b) => a.type.localeCompare(b.type)),
        providesTags: (_result, error) => (error ? [] : ['ContainerSettings']),
      }),

      updateContainerTimeouts: builder.mutation<ContainerTimeout, ContainerSettingsChange>({
        query: ({ id, body }) => ({
          body: { timeoutInterval: body.timeoutInterval },
          url: CONTAINER_SETTINGS_API_URLS.BY_ID(id),
          method: REQUEST_METHODS.PATCH,
        }),

        invalidatesTags: (_result, error) =>
          error ? [] : ['ContainerSettings', 'ContainerSettingsLogInfo'],
      }),

      getContainerSettingsLogInfo: builder.query<
        ContainerSettingsLogList,
        ContainerSettingsLogInfoQueryParams
      >({
        query: ({ page }) => {
          const params = {
            'pagination[page]': page,

            'filter[updatedAt][$ne]': null,
          };

          return {
            url: `${CONTAINER_SETTINGS_API_URLS.BASE_URL}${CONTAINER_SETTINGS_API_URLS.GET_HISTORY}`,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['ContainerSettingsLogInfo']),
      }),
    }),
  });

export const {
  useGetCurrentContainerTimeoutsQuery,
  useUpdateContainerTimeoutsMutation,
  useLazyGetContainerSettingsLogInfoQuery,
} = containerSettingsApi;
