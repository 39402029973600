/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ContainerBoxLogsListState } from 'modules/container-boxes/types';

import { containerBoxesApi } from '../api';

const initialState: ContainerBoxLogsListState = {
  page: 1,
  containerBoxLogs: [],
};

export const containerBoxLogsListSlice = createSlice({
  name: 'containerBoxLogsList',
  initialState,
  reducers: {
    resetContainerBoxLogs: (state) => {
      state.page = 1;
      state.containerBoxLogs = [];
    },
    setContainerBoxLogsListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      containerBoxesApi.endpoints.getContainerBoxLogInfo.matchFulfilled,
      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }
        if (payload.meta.page > 1) {
          state.containerBoxLogs = state.containerBoxLogs.concat(payload.data);
        } else {
          state.containerBoxLogs = payload.data;
        }
      },
    );
  },
});

export const containerBoxLogsListReducer = containerBoxLogsListSlice.reducer;

export const { setContainerBoxLogsListPage, resetContainerBoxLogs } =
  containerBoxLogsListSlice.actions;

export const selectContainerBoxLogsList = (state: RootState) => state.containerBoxLogsList;
