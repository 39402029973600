import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { accountsListReducer, accountWalletDetailsListReducer } from 'modules/accounts';
import {
  accountLogsListReducer,
  financialTransactionDetailsListReducer,
} from 'modules/accounts/store';
import { transactionRequestsOnAccountReducer } from 'modules/accounts/store/transactionRequestsOnAccountStore';
import {
  bagsDepositedListReducer,
  dropOffSessionsReducer,
  dropOffSessionLogsListReducer,
  moneyTransfersReducer,
  bagsPurchasedListReducer,
  bagDepositedLogsListReducer,
} from 'modules/activities/store';
import { authReducer } from 'modules/auth/store';
import { bagRollPriceLogsReducer } from 'modules/bag-roll-price/store/bagRollPriceStore';
import { certificatesListReducer } from 'modules/certificates/store';
import {
  containerBoxesListReducer,
  containerBoxLogsListReducer,
  waitingQueueReducer,
} from 'modules/container-boxes';
import { dashboardContainerBoxesReducer } from 'modules/container-boxes/store';
import { containerScannersReducer } from 'modules/container-boxes/store/containerScannerStore';
import { containerSettingsLogsReducer } from 'modules/container-settings/store';
import { dashboardReducer } from 'modules/dashboard/store';
import { devicesListReducer } from 'modules/devices';
import { deviceLogsListReducer } from 'modules/devices/store/deviceLogsListStore';
import { exportReducer } from 'modules/export/store';
import { ipAddressesListReducer } from 'modules/ip-addresses/store';
import { LocationLogsListReducer, locationsListReducer } from 'modules/locations/store';
import { customNotificationsListReducer } from 'modules/notifications/store';
import { OperatorLogsListReducer, operatorsListReducer } from 'modules/operators';
import { operatorAndStateRatesLogsReducer } from 'modules/prices/store/operatorAndStateRatesLogStore';
import { processingFeeLogsReducer } from 'modules/processing-fee';
import { dailySummaryReducer, dailyActivitiesSummaryReducer } from 'modules/reports/store';
import { supportContactLogsReducer } from 'modules/support-contact';
import { usersListReducer } from 'modules/users/store';
import {
  vendingMachineListReducer,
  vendingMachineLogsListReducer,
} from 'modules/vending-machines/store';

import { quickDropAdminApi } from './api';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'export'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  accountsList: accountsListReducer,
  accountLogsList: accountLogsListReducer,
  transactionRequestsOnAccount: transactionRequestsOnAccountReducer,
  accountWalletDetailsList: accountWalletDetailsListReducer,
  financialTransactionDetailsList: financialTransactionDetailsListReducer,
  usersList: usersListReducer,
  locationsList: locationsListReducer,
  locationLogsList: LocationLogsListReducer,
  bagsDepositedList: bagsDepositedListReducer,
  bagDepositedLogsList: bagDepositedLogsListReducer,
  bagsPurchasedList: bagsPurchasedListReducer,
  dropOffSessionList: dropOffSessionsReducer,
  dropOffSessionLogsList: dropOffSessionLogsListReducer,
  moneyTransfersList: moneyTransfersReducer,
  operatorsList: operatorsListReducer,
  operatorLogsList: OperatorLogsListReducer,
  devicesList: devicesListReducer,
  deviceLogsList: deviceLogsListReducer,
  vendingMachineLogList: vendingMachineLogsListReducer,
  vendingMachineList: vendingMachineListReducer,
  containerBoxesList: containerBoxesListReducer,
  containerBoxLogsList: containerBoxLogsListReducer,
  certificatesList: certificatesListReducer,
  ipAddressesList: ipAddressesListReducer,
  dashboard: dashboardReducer,
  export: exportReducer,
  waitingQueue: waitingQueueReducer,
  dailySummary: dailySummaryReducer,
  dailyActivitiesSummary: dailyActivitiesSummaryReducer,
  processingFeeLogs: processingFeeLogsReducer,
  bagRollPriceLogs: bagRollPriceLogsReducer,
  supportContactLogs: supportContactLogsReducer,
  customNotificationsList: customNotificationsListReducer,
  operatorAndStateRatesLogs: operatorAndStateRatesLogsReducer,
  dashboardContainerBoxes: dashboardContainerBoxesReducer,
  containerSettingsLogs: containerSettingsLogsReducer,
  containerScanners: containerScannersReducer,
  [quickDropAdminApi.reducerPath]: quickDropAdminApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const middlewares = [quickDropAdminApi.middleware];

export const storeFactory = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignoring all actions dispatched by redux-persist to avoid non-serializable value error.
          // See: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewares),
    devTools: import.meta.env.MODE !== 'production',
  });
export const store = storeFactory();
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred types, example: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
