import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'modules/common';

export const BreadCrumbs: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const crumbs = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb) => {
      return (
        <div key={crumb} className="capitalize flex items-center justify-center">
          <span>{`${decodeURI(crumb)}`}</span>
          <span className="mx-2">{`>`}</span>
        </div>
      );
    });

  return (
    <div className="flex flex-row justify-start text-white ml-4">
      {crumbs}
      <Button
        label={t('common.back')}
        className="ml-2 button bg-slate-100 text-gray-900 px-2"
        type="button"
        onClick={() => navigate(-1)}
      />
    </div>
  );
};
