import { FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SCANNING_DEVICE_TYPE, useForm } from 'modules/common';
import { useAddNewDeviceMutation } from 'modules/devices/api';
import {
  AddNewDeviceBody,
  AddNewDeviceFields,
  SCANNING_DEVICE_STATUS,
} from 'modules/devices/types';
import { AddNewDeviceSchema } from 'modules/devices/validation';
import { PATHS } from 'router/utils';

const defaultAddNewDeviceFields: AddNewDeviceFields = {
  organizationUId: undefined,
  deviceId: '',
  certificateUId: '',
  location: '',
  status: SCANNING_DEVICE_STATUS.ACTIVE,
  accessCode: '',
  noteData: '',
  type: SCANNING_DEVICE_TYPE.BAG_SCANNER,
  containerBoxUId: undefined,
  displayName: '',
};

export const useAddNewDevice = (initialValues: Partial<AddNewDeviceFields> = {}) => {
  const navigate = useNavigate();

  const initialAddNewDeviceFields = { ...defaultAddNewDeviceFields, ...initialValues };

  const formHook = useForm<AddNewDeviceFields>(
    AddNewDeviceSchema,
    initialAddNewDeviceFields,
    false,
  );

  const { form, toastFormErrors, formErrors, isFormValid, validate } = formHook;
  const [addNewDevice, { isLoading }] = useAddNewDeviceMutation();

  useEffect(() => {
    if (!isFormValid) {
      toastFormErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const addNewDeviceHandler = async (event: FormEvent) => {
    event.preventDefault();
    const errors = await validate();

    if (!errors) {
      const isBagScanner = form.type !== SCANNING_DEVICE_TYPE.CONTAINER_DOOR;
      const body: AddNewDeviceBody = {
        deviceId: form.deviceId,
        locationUId: isBagScanner ? form.location : '',
        certificateUId: form.certificateUId,
        status: form.status,
        noteData: form.noteData,
        accessCode: isBagScanner ? form.accessCode ?? '' : undefined,
        type: form.type,
        organizationUId: form.organizationUId,
        containerBoxUId: isBagScanner ? undefined : form.containerBoxUId,
        displayName: form.displayName,
      };
      const res = await addNewDevice(body).unwrap();
      if (res) {
        navigate(`${PATHS.SCANNERS}/${form.status}`);
      }
    }
  };

  return {
    addNewDeviceHandler,

    formHook,
    isLoading,
  };
};
