import { DropOffSessionDroppedBag, DropOffSessionUser } from 'modules/activities';
import { LogInfo, NoteData, ORDER, PageInfo, SCANNING_DEVICE_TYPE, Sorting } from 'modules/common';
import { ContainerBox } from 'modules/container-boxes';
import { TIMEOUT_TYPE } from 'modules/container-settings';
import { Address } from 'modules/locations';

export interface DeviceHistory {
  deviceId: string;
  type?: DEVICE_HISTORY_TYPE;
  note?: string;
  createdBy?: string;
  createdAt?: string;
}
export interface ScanningDevice {
  _id: string;
  deviceId: string;
  accessCode: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  blockedAt?: string;
  history?: DeviceHistory[];
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  createdAt?: string;
}

export enum SCANNING_DEVICE_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export enum DEVICE_HISTORY_TYPE {
  COMMENT = 'comment',
  STATUS_CHANGE = 'status_change',
}

export interface DevicesListQueryParams {
  page?: number;
  take?: number;
  sort?: DEVICES_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: SCANNING_DEVICE_STATUS | string;
  operatorUId?: string;
  deviceIdSearch?: string;
  type?: SCANNING_DEVICE_TYPE;
  isOnContainerBox?: boolean;
}

export enum DEVICES_LIST_SORT_OPTION_TABLE_KEYS {
  IN_SERVICE = 'inService',
  TOTAL_BAGS_SCANNED = 'totalBagsScanned',
  TOTAL_BAGS_TODAY = 'totalBagsToday',
  DEVICE_ID = 'deviceId',
}

export enum DEVICES_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  TOTAL_BAGS_SCANNED = 'totalBagsScanned',
  TOTAL_BAGS_TODAY = 'totalBagsToday',
  DEVICE_ID = 'deviceId',
}
export type DevicesListSorting = Sorting<DEVICES_LIST_SORT_OPTION_TABLE_KEYS>;

export const devicesListSortOptions: Record<
  DEVICES_LIST_SORT_OPTION_TABLE_KEYS,
  DEVICES_LIST_SORT_OPTIONS
> = {
  inService: DEVICES_LIST_SORT_OPTIONS.CREATED_AT,
  totalBagsScanned: DEVICES_LIST_SORT_OPTIONS.TOTAL_BAGS_SCANNED,
  totalBagsToday: DEVICES_LIST_SORT_OPTIONS.TOTAL_BAGS_TODAY,
  deviceId: DEVICES_LIST_SORT_OPTIONS.DEVICE_ID,
};
export interface DevicesListState {
  page: number;
  sorting: DevicesListSorting;
}

export enum SCANNER_STATE {
  AVAILABLE_FOR_CONNECTION = 'available_for_connection',
  RESERVED_FOR_USE = 'reserved_for_use',
  PREPARED_FOR_USE = 'prepared_for_use',
  PREPARING_TO_START = 'preparing_to_start',
  READY_TO_DROP = 'ready_to_drop',
  WAITING_FOR_DROP = 'waiting_for_drop',
  IN_USE = 'in_use',
  PREPARED_TO_CLOSE_SESSION = 'prepared_to_close_session',
  NOT_AVAILABLE = 'not_available',
  DROPPED = 'dropped',
}

export interface TimeoutJob {
  jobUId: string;
  type: TIMEOUT_TYPE;
  timeoutInterval: number;
  endedAt: Date;
}

export interface ActiveBagHandoverSession {
  _id: string;
  deviceId: string;
  deviceType: SCANNING_DEVICE_TYPE;
  droppedBags: DropOffSessionDroppedBag[];
  startedAt: string;
  endedAt: string;
  selectedDroppedBagAmount?: number;
  isExtendedSession?: boolean;
  user?: DropOffSessionUser;
  timeoutJobs?: TimeoutJob[];
}

export interface ContainerScannerState {
  deviceId: string;
  state: SCANNER_STATE;
  currentUserUId?: string;
  activeBagHandoverSession?: ActiveBagHandoverSession;
  lastHeartbeatTimestamp?: Date;
  updatedAt?: Date;
}

export interface DeviceItem {
  _id: string;
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  createdAt: string;
  blockedAt: string | null;
  history: DeviceHistory[];
  type: SCANNING_DEVICE_TYPE;
  totalBagsScanned: number;
  totalBagsToday: number;
  locationAddress: string;
  locationCity: string;
  locationZip: string;
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  noteData?: NoteData;
  containerBox: ContainerBox;
  displayName?: string;
  containerScannerState?: ContainerScannerState;
}

export interface DevicesList {
  meta: PageInfo;
  data: DeviceItem[];
}

export interface DeviceAccessCodeChange {
  accessCode?: string;
}

export interface DeviceChange extends DeviceAccessCodeChange {
  status?: SCANNING_DEVICE_STATUS;
  locationUId?: string;
  locationForRTK?: Address;
  certificateUId?: string;
  noteData?: NoteData;
  containerBoxUId?: string;
  displayName?: string;
}

export interface UpdateDeviceBody extends DeviceChange {
  id: string;
}

export interface UpdateDeviceAccessCodeBody extends DeviceAccessCodeChange {
  id: string;
}

export interface UpdateDeviceResponse {
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificateUId: string;
  blockedAt: string;
  displayName?: string;
}

export enum ADD_DEVICE_FIELDS {
  ORGANIZATIONUID = 'organizationUId',
  DEVICE_ID = 'deviceId',
  CERTIFICATE_UID = 'certificateUId',
  STATUS = 'status',
  LOCATION = 'location',
  NOTE_DATA = 'noteData',
  ACCESS_CODE = 'accessCode',
  TYPE = 'type',
  CONTAINER_BOX_UID = 'containerBoxUId',
  DISPLAY_NAME = 'displayName',
}

export interface AddNewDeviceFields {
  organizationUId?: string;
  deviceId: string;
  displayName?: string;
  certificateUId?: string;
  location?: string;
  status: SCANNING_DEVICE_STATUS;
  accessCode?: string;
  noteData?: string;
  type: SCANNING_DEVICE_TYPE;
  containerBoxUId?: string;
}
export interface UpdateDeviceFields {
  certificateUId?: string;
  locationUId?: string;
  status?: SCANNING_DEVICE_STATUS;
  accessCode?: string;
  displayName?: string;
}
export interface AddNewDeviceBody {
  deviceId: string;
  displayName?: string;
  accessCode?: string;
  locationUId?: string;
  organizationUId?: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificateUId?: string;
  containerBoxUId?: string;
  noteData?: string;
}

export interface AddNewDeviceResponse {
  deviceId: string;
  locationUId: string;
  organizationUId: string;
  type: SCANNING_DEVICE_TYPE;
  status: SCANNING_DEVICE_STATUS;
  certificate: string;
  displayName?: string;
}

export interface DeviceLogInfo extends LogInfo<DeviceItem> {
  id: string;
}

export interface DeviceLogInfoList {
  data: DeviceLogInfo[];
  meta: PageInfo;
}

export interface DeviceLogsListState {
  page: number;
  deviceLogs: DeviceLogInfo[];
}

export interface ContainerScannerItemStateItem extends DeviceItem {
  bagUIds?: string[];
  timer?: TimeoutJob;
}

export interface ContainerScannersState {
  data: ContainerScannerItemStateItem[];
  shouldBeUpdated: boolean;
}

export interface ContainerScannerBaseUpdate {
  deviceId: string;
  bagSessionId?: string;
}

export interface ContainerScannerAvailableData extends ContainerScannerBaseUpdate {
  isAvailable: boolean;
}

export interface ContainerScannerStateUpdateIncoming extends ContainerScannerBaseUpdate {
  bagUId?: string;
  timer?: TimeoutJob;
}

export interface ContainerScannerStateUpdate extends ContainerScannerStateUpdateIncoming {
  state: SCANNER_STATE;
}

export interface ContainerScannerWelcomeDropOffData extends ContainerScannerBaseUpdate {
  timer: TimeoutJob;
  session: ActiveBagHandoverSession;
}
