import { FC, PropsWithChildren } from 'react';

interface DropdownMenuProps extends PropsWithChildren {
  className?: string;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ children, className = 'w-44' }) => {
  return (
    <div className={`absolute top-pageHeader left-0 bg-menuHover rounded-b-lg ${className}`}>
      {children}
    </div>
  );
};
