import * as yup from 'yup';

import { StringSchemaGenerator, VALIDATION_ERRORS, getErrorMessage } from 'modules/common';
import {
  ADD_CONTAINER_BOX_FIELDS,
  AddNewContainerBoxFields,
  CONTAINER_BOX_STATUS,
  UpdateContainerBoxFields,
} from 'modules/container-boxes/types';

export const AddNewContainerBoxSchema: yup.Schema<AddNewContainerBoxFields> = yup.object().shape({
  name: StringSchemaGenerator(ADD_CONTAINER_BOX_FIELDS.NAME),
  locationUId: StringSchemaGenerator(ADD_CONTAINER_BOX_FIELDS.LOCATION_UID),
  organizationUId: StringSchemaGenerator(ADD_CONTAINER_BOX_FIELDS.ORGANIZATION_UID),
  status: yup
    .mixed<CONTAINER_BOX_STATUS>()
    .oneOf(Object.values(CONTAINER_BOX_STATUS))
    .nonNullable()
    .required(getErrorMessage(ADD_CONTAINER_BOX_FIELDS.STATUS, VALIDATION_ERRORS.REQUIRED_FIELD)),
  deviceIds: yup.array().of(yup.string().required()).required(),
});

export const UpdateContainerBoxSchema: yup.Schema<UpdateContainerBoxFields> = yup.object().shape({
  name: StringSchemaGenerator(ADD_CONTAINER_BOX_FIELDS.NAME),
  locationUId: yup.string(),
  status: yup
    .mixed<CONTAINER_BOX_STATUS>()
    .oneOf(Object.values(CONTAINER_BOX_STATUS))
    .nonNullable(),
});
