import { Toaster } from 'react-hot-toast';
import { Outlet, RouteObject } from 'react-router-dom';

import { ScrollToTop } from 'modules/common';
import {
  accountsRouter,
  activitiesRouter,
  authRouter,
  certificatesRouter,
  containerBoxesRouter,
  dashboardRouter,
  devicesRouter,
  vendingMachinesRouter,
  // ipAddressesRouter,
  locationsRouter,
  notificationsRouter,
  operatorsRouter,
  pricesRouter,
  processingFeeRouter,
  reportsRouter,
  supportContactRouter,
  usersRouter,
  containerSettingsRouter,
} from 'router/ModuleRouters';
import { bagRollPriceRouter } from 'router/ModuleRouters/bagRollPriceRouter';

export function RootRouter(): RouteObject[] {
  return [
    {
      element: (
        <>
          <ScrollToTop />
          <Toaster />
          <Outlet />
        </>
      ),
      children: [
        ...authRouter,
        ...accountsRouter,
        ...activitiesRouter,
        ...certificatesRouter,
        ...dashboardRouter,
        ...devicesRouter,
        ...locationsRouter,
        ...notificationsRouter,
        ...operatorsRouter,
        ...pricesRouter,
        ...processingFeeRouter,
        ...reportsRouter,
        ...supportContactRouter,
        ...usersRouter,
        ...containerBoxesRouter,
        ...containerSettingsRouter,
        ...vendingMachinesRouter,
        ...bagRollPriceRouter,
        /* TODO IP RESTRICTION Put back once relevant, removed for the time being */
        /* ...ipAddressesRouter, */
      ],
    },
  ];
}
