import { LogInfo, NoteData, ORDER, PageInfo, Sorting } from 'modules/common';
import { ContainerBox } from 'modules/container-boxes';

export enum VENDING_MACHINE_HISTORY_TYPE {
  COMMENT = 'comment',
  STATUS_CHANGE = 'status_change',
}

export interface VendingMachineHistory {
  deviceId: string;
  type?: VENDING_MACHINE_HISTORY_TYPE;
  note?: string;
  createdBy?: string;
  createdAt?: string;
}

export enum VENDING_MACHINE_STATUS {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  ARCHIVE = 'archive',
}

export interface VendingMachine {
  _id: string;
  deviceId: string;
  containerUId: string;
  isOpen: boolean;
  history?: VendingMachineHistory[];
  status: VENDING_MACHINE_STATUS;
  createdAt?: string;
}

export enum VENDING_MACHINE_LIST_SORT_OPTION_TABLE_KEYS {
  IN_SERVICE = 'inService',
  DEVICE_ID = 'deviceId',
}

export enum VENDING_MACHINE_LIST_SORT_OPTIONS {
  CREATED_AT = 'createdAt',
  DEVICE_ID = 'deviceId',
}

export interface VendingMachineListQueryParams {
  page?: number;
  take?: number;
  sort?: VENDING_MACHINE_LIST_SORT_OPTIONS;
  order?: ORDER;
  status?: VENDING_MACHINE_STATUS | string;
  operatorUId?: string;
  deviceIdSearch?: string;
  isOnContainerBox?: boolean;
}

export type VendingMachineListSorting = Sorting<VENDING_MACHINE_LIST_SORT_OPTION_TABLE_KEYS>;

export const vendingMachineListSortOptions: Record<
  VENDING_MACHINE_LIST_SORT_OPTION_TABLE_KEYS,
  VENDING_MACHINE_LIST_SORT_OPTIONS
> = {
  inService: VENDING_MACHINE_LIST_SORT_OPTIONS.CREATED_AT,
  deviceId: VENDING_MACHINE_LIST_SORT_OPTIONS.DEVICE_ID,
};
export interface VendingMachineListState {
  page: number;
  sorting: VendingMachineListSorting;
}
export interface VendingMachineItem {
  _id: string;
  deviceId: string;
  createdAt: string;
  history: VendingMachineHistory[];
  status: VENDING_MACHINE_STATUS;
  noteData?: NoteData;
  isOpen: boolean;
  containerBoxUId: string;
  containerBox: ContainerBox;
}

export interface VendingMachineList {
  meta: PageInfo;
  data: VendingMachineItem[];
}

export interface VendingMachineAccessCodeChange {
  accessCode?: string;
}

export interface VendingMachineChange extends VendingMachineAccessCodeChange {
  status?: VENDING_MACHINE_STATUS;
  isOpen?: boolean;
  noteData?: NoteData;
  containerBoxUId?: string;
}

export interface UpdateVendingMachineBody extends VendingMachineChange {
  id: string;
}

export interface UpdateVendingMachineResponse {
  deviceId: string;
  status: VENDING_MACHINE_STATUS;
  isOpen: boolean;
}

export enum ADD_VENDING_MACHINE_FIELDS {
  DEVICE_ID = 'deviceId',
  STATUS = 'status',
  OPEN = 'isOpen',
  NOTE_DATA = 'noteData',
  CONTAINER_BOX_UID = 'containerBoxUId',
}

export interface AddNewVendingMachineFields {
  deviceId: string;
  status: VENDING_MACHINE_STATUS;
  isOpen: boolean;
  noteData?: string;
  containerBoxUId?: string;
}
export interface UpdateVendingMachineFields {
  status?: VENDING_MACHINE_STATUS;
}
export interface AddNewVendingMachineBody {
  deviceId: string;
  status: VENDING_MACHINE_STATUS;
  isOpen: boolean;
  containerBoxUId?: string;
  noteData?: string;
}

export interface AddNewVendingMachineResponse {
  deviceId: string;
  status: VENDING_MACHINE_STATUS;
  isOpen: boolean;
}

export interface VendingMachineLogInfo extends LogInfo<VendingMachineItem> {
  id: string;
}

export interface VendingMachineLogInfoList {
  data: VendingMachineLogInfo[];
  meta: PageInfo;
}

export interface VendingMachineLogsListState {
  page: number;
  vendingMachineLogs: VendingMachineLogInfo[];
}

export enum UNIT_TYPES {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'notAvailable',
}
