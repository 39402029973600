import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccountIcon from 'assets/icons/account.svg';
import ActivityIcon from 'assets/icons/activity.svg';
import CubeIcon from 'assets/icons/cube.svg';
import DashboardIcon from 'assets/icons/dashboard.svg';
import LocationIcon from 'assets/icons/location.svg';
import NotificationIcon from 'assets/icons/notification.svg';
import OperatorIcon from 'assets/icons/operator.svg';
import ReportIcon from 'assets/icons/report.svg';
import ScannerIcon from 'assets/icons/scanner.svg';
import UserIcon from 'assets/icons/user.svg';
import VendingMachineIcon from 'assets/icons/vending-machine.svg';
import { getIsLoggedIn, selectIsSuperuser } from 'modules/auth';
import { Image, ProfileDropDownMenu, VisualDivider } from 'modules/common/components';
import { useAppSelector } from 'modules/common/hooks';
import { CONTAINER_BOX_STATUS } from 'modules/container-boxes';
import { SCANNING_DEVICE_STATUS } from 'modules/devices';
import { LOCATIONS_STATUSES } from 'modules/locations/types';
import { OPERATOR_STATUSES } from 'modules/operators/types';
import { USER_STATUS } from 'modules/users';
import { VENDING_MACHINE_STATUS } from 'modules/vending-machines';
import { PATHS } from 'router/utils';

import { HeaderItem } from './HeaderItem';
import { SubMenu } from './SubMenu';

export const HeaderContent: FC = () => {
  const { t } = useTranslation();
  const isSuperuser = useAppSelector(selectIsSuperuser);
  const isLoggedIn = useAppSelector(getIsLoggedIn);

  if (!isLoggedIn) {
    return (
      <>
        <p className="text-lg font-semibold ml-4">{t('navbar.titleOnLogin')}</p>
        <div className="flex ml-auto mr-2 justify-center items-center">
          <Image src={UserIcon} alt="user-icon" />
        </div>
      </>
    );
  }

  return (
    <>
      <SubMenu />
      <div className="flex items-center">
        <HeaderItem img={DashboardIcon} title={t('navbar.dashboard')} to={PATHS.DASHBOARD} />
        <HeaderItem
          img={AccountIcon}
          title={t('navbar.accounts')}
          to={`${PATHS.ACCOUNTS}/${USER_STATUS.ACTIVE}`}
          id="pageHeaderAccounts"
        />

        <HeaderItem
          img={ActivityIcon}
          title={t('navbar.activities')}
          to={PATHS.BAGS_DEPOSITED}
          id="pageHeaderActivities"
        />
        <VisualDivider />

        {isSuperuser && (
          <HeaderItem
            img={OperatorIcon}
            title={t('navbar.operators')}
            to={`${PATHS.OPERATORS}/${OPERATOR_STATUSES.ACTIVE}`}
          />
        )}
        <HeaderItem
          img={LocationIcon}
          title={t('navbar.locations')}
          to={`${PATHS.LOCATIONS}/${LOCATIONS_STATUSES.ACTIVE}`}
        />
        <HeaderItem
          img={CubeIcon}
          title={t('navbar.containerBoxes')}
          to={`${PATHS.CONTAINER_BOXES}/${CONTAINER_BOX_STATUS.ACTIVE}`}
        />
        <HeaderItem
          img={ScannerIcon}
          title={t('navbar.scanningDevices')}
          to={`${PATHS.SCANNERS}/${SCANNING_DEVICE_STATUS.ACTIVE}`}
        />
        <HeaderItem
          img={VendingMachineIcon}
          title={t('navbar.vendingMachine')}
          to={`${PATHS.VENDING_MACHINES}/${VENDING_MACHINE_STATUS.ACTIVE}`}
        />
        <VisualDivider />
        {isSuperuser ? (
          <HeaderItem img={ReportIcon} to={PATHS.REPORTS_DAILY} title={t('navbar.reports')} />
        ) : null}
      </div>
      <div className="flex ml-auto mr-2 justify-center items-center">
        <div className="w-[60px]">
          <Image src={NotificationIcon} alt="notification-icon" />
        </div>
      </div>
      <ProfileDropDownMenu />
    </>
  );
};
