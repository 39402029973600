/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ContainerSettingsLogState } from 'modules/container-settings/types';

import { containerSettingsApi } from '../api';

const initialState: ContainerSettingsLogState = {
  page: 1,
  lastPage: 1,
  containerSettingsLogs: [],
};

export const containerSettingsLogsSlice = createSlice({
  name: 'containerSettingsLogs',
  initialState,
  reducers: {
    resetContainerSettingsLogs: (state) => {
      state.page = 1;
      state.containerSettingsLogs = [];
    },
    setContainerSettingsLogsPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      containerSettingsApi.endpoints.getContainerSettingsLogInfo.matchFulfilled,

      (state, { payload }) => {
        if (!payload.data.length) {
          return;
        }

        if (payload.meta.page > 1) {
          state.lastPage = payload.meta.lastPage;
          state.containerSettingsLogs = state.containerSettingsLogs.concat(payload.data);
        } else {
          state.lastPage = payload.meta.lastPage;
          state.containerSettingsLogs = payload.data;
        }
      },
    );
  },
});

export const containerSettingsLogsReducer = containerSettingsLogsSlice.reducer;

export const { setContainerSettingsLogsPage, resetContainerSettingsLogs } =
  containerSettingsLogsSlice.actions;

export const selectContainerSettingsLogs = (state: RootState) =>
  state.containerSettingsLogs.containerSettingsLogs;

export const selectContainerSettingsLogsPage = (state: RootState) =>
  state.containerSettingsLogs.page;

export const selectContainerSettingsLogsLastPage = (state: RootState) =>
  state.containerSettingsLogs.lastPage;
