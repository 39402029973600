import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { FC, useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import 'chart.js/auto'; // ADD THIS SO MULTIPLE CHARTS WILL WORK

import { Layout, Spinner } from 'modules/common';
import {
  useDashboard,
  generateBagsTodayData,
  getTotalBags,
  ActiveScanner,
  SuspendedScanner,
  BAGS_WAITNG_LEGEND,
  BagsWaitingChart,
  ActiveContainerBox,
  SuspendedContainerBox,
} from 'modules/dashboard';
import { BagsTodayChartData } from 'modules/dashboard/types';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export const Dashboard: FC = () => {
  const { t } = useTranslation();
  const {
    bagsToday,
    isLoading,
    activeScanners,
    suspendedScanners,
    bagsWaitingPercentage,
    scannersUpdatedAt,
    bagsTodayUpdatedAt,
    bagsWaitingUpdatedAt,
    activeContainerBoxes,
    suspendedContainerBoxes,
  } = useDashboard();

  const [bagsTodayData, setBagsTodayData] = useState<BagsTodayChartData>();
  const [totalBags, setTotalBags] = useState(0);
  const pieRef = useRef();

  useEffect(() => {
    if (bagsToday) {
      setBagsTodayData(generateBagsTodayData(bagsToday, t('dashboard.label')));
      setTotalBags(getTotalBags(bagsToday));
    }
  }, [bagsToday, t]);

  return (
    <Layout>
      <div className="w-100 mt-16 min-h-full mx-8 text-black">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex justify-left gap-20">
              <div className="flex flex-col justify-center min-w-[25%]">
                <h2 className="font-bold">{t('dashboard.bagsToday', { count: totalBags })}</h2>
                <h4 className="font-thin text-sm mb-2">
                  {t('dashboard.refresh', { value: bagsTodayUpdatedAt })}
                </h4>
                {bagsTodayData ? (
                  <Pie
                    ref={pieRef}
                    data={bagsTodayData}
                    options={{
                      layout: { padding: 1 },
                      plugins: {
                        legend: {
                          onHover: (event) => {
                            if (event.native?.target) {
                              // eslint-disable-next-line no-param-reassign
                              (event.native.target as HTMLDivElement).style.cursor = 'pointer';
                            }
                          },
                          onLeave(event) {
                            if (event.native?.target) {
                              // eslint-disable-next-line no-param-reassign
                              (event.native.target as HTMLDivElement).style.cursor = 'default';
                            }
                          },
                          position: 'right',
                        },
                      },
                    }}
                  />
                ) : null}
              </div>
              <div className="flex flex-col min-w-[25%]">
                <h2 className="font-bold">{t('dashboard.waitingToProcess')}</h2>
                <h4 className="font-thin text-sm mb-2">
                  {t('dashboard.waitingToProcessDescription')}
                </h4>
                <h4 className="font-thin text-sm mb-2">
                  {t('dashboard.refresh', { value: bagsWaitingUpdatedAt })}
                </h4>
                <BagsWaitingChart
                  data={{
                    labels: ['%'],
                    datasets: [
                      {
                        label: t(`dashboard.legend.${BAGS_WAITNG_LEGEND.ONE_DAY}`),
                        data: [bagsWaitingPercentage?.oneDay ?? ''],
                      },
                      {
                        label: t(`dashboard.legend.${BAGS_WAITNG_LEGEND.TWO_DAYS}`),
                        data: [bagsWaitingPercentage?.twoDays ?? ''],
                      },
                      {
                        label: t(`dashboard.legend.${BAGS_WAITNG_LEGEND.THREE_DAYS}`),
                        data: [bagsWaitingPercentage?.threeDays ?? ''],
                      },
                      {
                        label: t(`dashboard.legend.${BAGS_WAITNG_LEGEND.FOUR_OR_MORE_DAYS}`),
                        data: [bagsWaitingPercentage?.fourAndMoreDays ?? ''],
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div>
              <h2 className="font-bold">
                {t('dashboard.containerBoxesActive', { count: activeContainerBoxes.length })}
              </h2>
              <div className="flex flex-row flex-wrap gap-4">
                {!!activeContainerBoxes?.length &&
                  activeContainerBoxes.map((containerBox, index) => (
                    <ActiveContainerBox
                      key={containerBox.containerBoxUId}
                      index={index + 1}
                      containerBox={containerBox}
                    />
                  ))}
              </div>
              <h2 className="font-bold mt-8">
                {t('dashboard.scannersActive', { count: activeScanners.length })}
              </h2>
              <h4 className="font-thin text-sm mb-2">
                {t('dashboard.refresh', { value: scannersUpdatedAt })}
              </h4>
              <div className="flex flex-row flex-wrap gap-4">
                {!!activeScanners?.length &&
                  activeScanners.map((scanner, i) => (
                    <ActiveScanner
                      key={scanner.deviceId}
                      index={i + 1}
                      certificate={scanner.certificateUId ?? ''}
                      bagsScanned={scanner.totalBagsScanned}
                      bagsScannedToday={scanner.totalBagsToday}
                      deviceId={scanner.deviceId}
                    />
                  ))}
              </div>
              <h2 className="font-bold mt-8">
                {t('dashboard.scannersSuspended', { count: suspendedScanners.length })}
              </h2>
              <div className="flex flex-row flex-wrap gap-4">
                {!!suspendedScanners?.length &&
                  suspendedScanners.map((scanner) => (
                    <SuspendedScanner key={scanner.deviceId} deviceId={scanner.deviceId} />
                  ))}
              </div>
              <h2 className="font-bold mt-8">
                {t('dashboard.containerBoxesSuspended', { count: suspendedContainerBoxes.length })}
              </h2>
              <div className="flex flex-row flex-wrap gap-4">
                {!!suspendedContainerBoxes?.length &&
                  suspendedContainerBoxes.map((containerBox) => (
                    <SuspendedContainerBox
                      key={containerBox._id}
                      id={containerBox._id}
                      name={containerBox.name}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
