import { GROUP_ROLE_ACCESS, USER_ROLE_KEYS, getRoles } from 'modules/auth';
import { useAppSelector } from 'modules/common';

export const useHasGroupAccess = () => {
  const roles = useAppSelector(getRoles);

  const hasGroupAccess = (desiredRole: USER_ROLE_KEYS, desiredAccess: GROUP_ROLE_ACCESS) => {
    const acceptableAccesses = [desiredAccess];

    if (desiredAccess === GROUP_ROLE_ACCESS.READ) {
      acceptableAccesses.push(GROUP_ROLE_ACCESS.WRITE);
    }

    return roles.some(
      ({ role, access }) => role === desiredRole && acceptableAccesses.includes(access),
    );
  };

  return hasGroupAccess;
};
