/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'config/store';
import { ORDER } from 'modules/common';
import {
  VENDING_MACHINE_LIST_SORT_OPTION_TABLE_KEYS,
  VendingMachineListState,
} from 'modules/vending-machines/types';

const initialState: VendingMachineListState = {
  page: 1,
  sorting: { sort: VENDING_MACHINE_LIST_SORT_OPTION_TABLE_KEYS.IN_SERVICE, order: ORDER.DESC },
};

export const vendingMachineListSlice = createSlice({
  name: 'vendingMachinesList',
  initialState,
  reducers: {
    setVendingMachinesListSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setVendingMachinesListPage: (state, { payload }) => {
      state.page = payload;
    },
  },
});

export const vendingMachineListReducer = vendingMachineListSlice.reducer;

export const { setVendingMachinesListSorting, setVendingMachinesListPage } =
  vendingMachineListSlice.actions;

export const selectVendingMachineList = (state: RootState) => state.vendingMachineList;
