/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import ChevronIcon from 'assets/icons/chevron.svg';
import { Image } from 'modules/common';

interface DropdownListProps {
  id?: string;
  menuItems: string[];
  onSelect: (item: string) => void;
  show?: (item: string | null) => string;
  defaultSelected: string | null;
  disabled?: boolean;
  className?: string;
  height?: 'm' | 'l';
  hidden?: boolean;
}

export const DropdownList: FC<DropdownListProps> = ({
  id,
  menuItems,
  defaultSelected,
  onSelect,
  disabled,
  show = (item) => item,
  className = '',
  height = 'm',
  hidden,
}) => {
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(defaultSelected);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [setSelected, defaultSelected]);

  const toggleVisibility = () => setVisible((prev) => !prev);
  useOnClickOutside(dropdownRef, () => setVisible(false));

  const heightClassName = height === 'm' ? `h-5` : 'h-10';
  const listPositionClassName = height === 'm' ? `top-5` : 'top-10';
  const cursorClassName = disabled ? 'cursor-not-allowed' : 'cursor-pointer';

  return (
    <div
      className="text-label flex flex-col gap-2 justify-center"
      ref={dropdownRef}
      hidden={hidden}
      id={id}
    >
      <div
        className={`flex items-center justify-between border border-neutral-300 text-input relative select-none pr-2 ${className} ${heightClassName} ${cursorClassName}`}
        onClick={toggleVisibility}
      >
        <span className="whitespace-nowrap px-2">{show(selected)}</span>
        {!disabled && (
          <>
            <Image src={ChevronIcon} alt="chevronDown" />
            {visible && (
              <ul
                className={`grid absolute w-full bg-neutral-50 z-20 shadow max-h-[150px] overflow-auto ${listPositionClassName}`}
              >
                {menuItems.map((item) => {
                  if (item === selected) return null;

                  return (
                    <li
                      className="text-neutral-900 hover:bg-neutral-200 px-2 py-2"
                      key={item}
                      onClick={() => {
                        onSelect(item);
                        setSelected(item);
                      }}
                    >
                      {show(item)}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};
