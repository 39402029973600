import { FC } from 'react';

interface VisualDividerProps {
  orientation?: 'horizontal' | 'vertical';
  color?: string;
}

export const VisualDivider: FC<VisualDividerProps> = ({
  orientation = 'vertical',
  color = 'bg-neutral-500',
}) => {
  const orientationStyling =
    orientation === 'horizontal' ? 'h-[1px] min-w-[47px]' : 'w-[1px] min-h-[47px]';

  return <div className={`${color} ${orientationStyling}`} />;
};
