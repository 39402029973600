import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { setOperator } from 'modules/auth';
import { BreadCrumbs, OperatorSelector } from 'modules/common/components';
import { ALL } from 'modules/common/constants';
import { useAppDispatch, useOperatorSelect } from 'modules/common/hooks';

interface SubHeaderBarProps {
  withOperatorSelector?: boolean;
}

export const SubHeaderBar: FC<SubHeaderBarProps> = ({ withOperatorSelector }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedOperatorAuth, getOperatorById, operatorUIdList, operators, onlyHasOneOperator } =
    useOperatorSelect();

  const onOperatorSelect = (item: string) => {
    const newOperator = item === ALL ? null : getOperatorById(item);
    dispatch(setOperator(newOperator));
  };

  return (
    <div className="h-8 w-screen bg-menuHover flex fixed items-center">
      <div className="w-full text-neutral-500 flex justify-between">
        <BreadCrumbs />
        {withOperatorSelector && !onlyHasOneOperator && (
          <OperatorSelector
            onSelect={onOperatorSelect}
            className="bg-white w-[200px] h-6 ml-auto mr-2 text-sm"
            menuItems={[t(`common.${ALL}`), ...operatorUIdList]}
            hidden={operators.length < 2}
            defaultSelected={selectedOperatorAuth?.organizationUId || ''}
          />
        )}
      </div>
    </div>
  );
};
