import { FC } from 'react';

import { HeaderContent } from './HeaderContent';
import { SubHeaderBar } from './SubHeaderBar';

interface PageHeaderProps {
  withSubheaderBar?: boolean;
  withOperatorSelector?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ withSubheaderBar, withOperatorSelector }) => {
  return (
    <div className="fixed min-w-full z-10">
      <nav className="flex bg-neutral-700 h-pageHeader items-center relative z-20 pr-4 ">
        <HeaderContent />
      </nav>
      {withSubheaderBar && <SubHeaderBar withOperatorSelector={withOperatorSelector} />}
    </div>
  );
};
