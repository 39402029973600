export const EXPORT_API_URLS = {
  EXPORT_DEVICES: 'admin/export/scanning-devices',
  EXPORT_VENDING_MACHINES: 'admin/export/vending-machines',
  EXPORT_DROP_OFFS: 'admin/export/dropoffs',
  EXPORT_USERS: 'admin/export/users',
  EXPORT_OPERATORS: 'admin/export/organizations',
  EXPORT_CONTAINER_BOXES: 'admin/export/container-boxes',
  EXPORT_BAG_PURCHASES: 'admin/export/bag-purchases',
  EXPORT_DROP_OFF_SESSIONS: 'admin/export/bag-handover-sessions',
  EXPORT_MONEY_TRANSFERS: 'admin/export/payouts',
  EXPORT_DROPPED_BAGS: 'admin/export/dropped-bags',
  EXPORT_DAILY_SUMMARIES: 'admin/export/daily-summaries-report',
  EXPORT_DAILY_ACTIVITIES_SUMMARY: 'admin/export/daily-activities-summary-report',
  ACCOUNT_STATEMENTS: 'admin/export/account-statements',
  TRANSACTIONS: 'admin/export/transactions',
};
