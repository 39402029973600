import { quickDropAdminApi } from 'config/api';
import i18n from 'config/translation';
import {
  CommentPostRequest,
  LogInfoQueryParams,
  REGEX_OPTIONS,
  REQUEST_METHODS,
} from 'modules/common/types';
import { toastSuccessOrWarn } from 'modules/common/utils';
import {
  AddNewVendingMachineBody,
  AddNewVendingMachineResponse,
  VendingMachineItem,
  VendingMachineLogInfo,
  VendingMachineLogInfoList,
  VendingMachineList,
  VendingMachineListQueryParams,
  VENDING_MACHINE_STATUS,
  UpdateVendingMachineBody,
  UpdateVendingMachineResponse,
} from 'modules/vending-machines/types';

import { VENDING_MACHINES_API_URLS } from '../constants';

export const vendingMachinesApi = quickDropAdminApi
  .enhanceEndpoints({
    addTagTypes: ['VendingMachines', 'VendingMachineLogInfo', 'ContainerBoxes'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getVendingMachines: builder.query<VendingMachineList, VendingMachineListQueryParams>({
        query: ({ page, sort, order, status, operatorUId, take, deviceIdSearch }) => {
          const params: { [k: string]: string | number | undefined | null } = {
            'pagination[page]': page,
            'pagination[take]': take,
            [`sort[${sort}]`]: order,
            'filters[status][$in][]': status,
            'filters[organizationUId][$in][]': operatorUId,
            'filters[deviceId][$regex]': deviceIdSearch || undefined,
            'filters[deviceId][$options]': deviceIdSearch
              ? REGEX_OPTIONS.CASE_INSENSITIVE
              : undefined,
          };

          return {
            url: VENDING_MACHINES_API_URLS.VENDING_MACHINES_LIST,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (result) => (!result ? [] : ['VendingMachines']),
      }),
      getVendingMachine: builder.query<VendingMachineItem, string>({
        query: (id: string) => ({
          url: VENDING_MACHINES_API_URLS.VENDING_MACHINE_BY_ID(id),
          method: REQUEST_METHODS.GET,
        }),
        providesTags: (result) => (!result ? [] : ['VendingMachines']),
      }),
      updateVendingMachine: builder.mutation<
        UpdateVendingMachineResponse,
        UpdateVendingMachineBody
      >({
        query: (body) => {
          const { id, ...rest } = body;
          return {
            body: rest,
            url: VENDING_MACHINES_API_URLS.VENDING_MACHINE_BY_ID(id),
            method: REQUEST_METHODS.PATCH,
          };
        },
        extraOptions: { disableDefaultSuccessToaster: true },
        invalidatesTags: (_result, error, args) => {
          const tagsToInvalidate: (
            | 'VendingMachines'
            | 'VendingMachineLogInfo'
            | 'ContainerBoxes'
          )[] = [];
          if (!error) {
            tagsToInvalidate.push('VendingMachines', 'VendingMachineLogInfo');
            if (args.containerBoxUId) {
              tagsToInvalidate.push('ContainerBoxes');
            }
          }
          return tagsToInvalidate;
        },
      }),
      addNewVendingMachine: builder.mutation<
        AddNewVendingMachineResponse,
        AddNewVendingMachineBody
      >({
        query: ({ noteData, ...body }) => ({
          url: VENDING_MACHINES_API_URLS.VENDING_MACHINES_ADD,
          body: { ...body, noteData: { text: noteData } },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['VendingMachines']),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            const createdVendingMachine = await queryFulfilled;

            const desiredToBeActive = args.status === VENDING_MACHINE_STATUS.ACTIVE;
            const backendChangedStatusToSuspended =
              desiredToBeActive &&
              createdVendingMachine.data.status === VENDING_MACHINE_STATUS.SUSPENDED;

            toastSuccessOrWarn({
              isWarning: backendChangedStatusToSuspended,
              warningMsg: i18n.t('vendingMachines.backendChangesStatusToSuspended'),
            });
          } catch {
            // error handled in config api
          }
        },
        extraOptions: { disableDefaultSuccessToaster: true },
      }),
      getVendingMachineLogInfo: builder.query<VendingMachineLogInfoList, LogInfoQueryParams>({
        query: ({ page, id }) => {
          const params = {
            'pagination[page]': page,
            'filters[deviceId]': id,
          };
          return {
            url: VENDING_MACHINES_API_URLS.VENDING_MACHINE_LOG_INFO,
            params,
            method: REQUEST_METHODS.GET,
          };
        },
        providesTags: (_result, error) => (error ? [] : ['VendingMachineLogInfo']),
      }),
      addVendingMachineComment: builder.mutation<VendingMachineLogInfo, CommentPostRequest>({
        query: ({ id, comment }) => ({
          url: VENDING_MACHINES_API_URLS.VENDING_MACHINE_LOG_INFO,
          body: { deviceId: id, comment },
          method: REQUEST_METHODS.POST,
        }),
        invalidatesTags: (_result, error) => (error ? [] : ['VendingMachineLogInfo']),
      }),
    }),
  });

export const {
  useGetVendingMachinesQuery,
  useGetVendingMachineQuery,
  useUpdateVendingMachineMutation,
  useAddNewVendingMachineMutation,
  useLazyGetVendingMachineLogInfoQuery,
  useAddVendingMachineCommentMutation,
} = vendingMachinesApi;
