import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useApiVersionQuery } from 'modules/api-version';
import { Footer, PageHeader, ReauthPopUp } from 'modules/common/components';
import { VERSION_NUMBER, BUILD_NUMBER } from 'modules/common/constants';
import { useAutoLogout, useStylesByEnv } from 'modules/common/hooks';

export interface LayoutProps extends PropsWithChildren {
  withOperatorSelector?: boolean;
}

interface MainLayoutProps extends LayoutProps {
  withSubheaderBar?: boolean;
}

export const Layout: FC<MainLayoutProps> = ({
  children,
  withSubheaderBar,
  withOperatorSelector,
}) => {
  const { t } = useTranslation();
  const { data: apiVersion } = useApiVersionQuery();

  const { remainingTimeInMs, reauth } = useAutoLogout();

  const paddingForHeader = withSubheaderBar ? 'pt-subHeaderBar' : 'pt-pageHeader';

  const envStyles = useStylesByEnv();

  return (
    <>
      {remainingTimeInMs && (
        <ReauthPopUp
          isOpen={!!remainingTimeInMs}
          onClick={reauth}
          remainingTimeInMs={remainingTimeInMs}
        />
      )}
      <PageHeader withSubheaderBar={withSubheaderBar} withOperatorSelector={withOperatorSelector} />
      <div className={`min-h-[calc(100vh-41px)] pb-[41px] mb-[41px] grid ${paddingForHeader}`}>
        {children}
      </div>
      <Footer
        className={`${envStyles.bgStyling} ${envStyles.textStyling}`}
        text={`${t('footer.adminVersion', { VERSION_NUMBER, BUILD_NUMBER })}${
          apiVersion
            ? ` - ${t('footer.apiVersion', {
                apiVersion: apiVersion.version,
                tagSuffix: apiVersion.tagSuffix,
              })}`
            : ``
        }`}
      />
    </>
  );
};
